import { useEffect, useState } from 'react';
import { Checkbox, Select } from 'antd';

const SelectItem = ({ item, onChange }) => {
  return (
    <div className="flex">
      <div className="flex-1 border border-r-0">
        <div className="p-2 h-[32px]">{item.label}</div>
      </div>
      <div className="flex-1 border">
        <Select
          bordered={false}
          placeholder="请选择"
          options={item.options}
          defaultValue={item.value}
          className="h-full"
          onChange={onChange}
          disabled={item?.disabled}
        />
      </div>
    </div>
  );
};

const MultipleSelect = ({ headers, options, ...props }) => {
  const [items, setItems] = useState<{ name: string; key: string }[]>([]);
  const onItemChange = (e, item) => {
    setItems([
      ...items.filter(({ key }) => key !== item.key),
      { name: e, key: item.key },
    ]);
  };

  useEffect(() => {
    props.onChange(items);
  }, [items]);

  return (
    <div className="flex w-full flex-col">
      <div className="flex">
        {headers.map((header) => (
          <div
            key={header}
            className="flex-1 border p-2 border-b-0 first:border-r-0 select-none"
          >
            {header}
          </div>
        ))}
      </div>
      {options.map((item) => (
        <SelectItem
          item={item}
          key={item.key}
          onChange={(e) => onItemChange(e, item)}
        />
      ))}
    </div>
  );
};

export default MultipleSelect;
