import { memo } from 'react';
import { SettingOutlined } from '@ant-design/icons';

type LoadingProps = {
  children?: React.ReactNode;
};

const Loading: React.FC<LoadingProps> = memo(({ children }) => {
  return (
    <div className=" h-screen w-full bg-white dark:bg-slate-800 dark:text-white flex items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <SettingOutlined
          className="text-gray-800"
          spin
          style={{ fontSize: 40, width: 40, height: 40 }}
        />
        {children ? children : <h4>页面正在加载中...</h4>}
      </div>
    </div>
  );
});

export default Loading;
