const Statistic = () => {
  return (
    <div className="flex flex-row ">
      <div className="item">
        <div className="num">5</div>
        <div className="title">团队人数</div>
      </div>
      <div className="item">
        <div className="num">600</div>
        <div className="title">任务数量</div>
      </div>
      <div className="item">
        <div className="num">200</div>
        <div className="title">已完成</div>
      </div>
      <div className="item">
        <div className="num">300</div>
        <div className="title">未完成</div>
      </div>
    </div>
  );
};

export default Statistic;
