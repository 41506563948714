const PageHeader = ({ title, description }) => {
  return (
    <div className=" divide-y">
      <div className="flex flex-col">
        <h3 className="w-full py-2">{title}</h3>
        <span className="w-full py-2 text-base mb-5">{description}</span>
      </div>
    </div>
  );
};

export default PageHeader;
