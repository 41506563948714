import { Article, Event, Level, PageData, Project, Tag, User } from '@/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@/utils/request';
import { CreatArticlesParams, CreateEventParams, QueryArticlesParams,QueryEventParams,QueryTagParams,QueryTeamsParams, RemoveEventParams, UpdateArticlesParams, UpdateEventParams } from './types';
import { projectActions } from '.';

export const queryArticles = createAsyncThunk<
  PageData<Article>,
  QueryArticlesParams
>('project/queryArticles', async (params) => {
  const data = await request<PageData<Article>>('/api/v1/articles', {
    method: 'GET',
    params,
  });
  return data;
});

export const createArticle = createAsyncThunk<Article, CreatArticlesParams>(
  'project/createArticle',
  async ({ reject, resolve,tags,...article }) => {
    const { data } = await request<Article>(
      '/api/v1/articles',
      {
        method: 'POST',
        data: { article,tags },
      },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);


export const updateArticle = createAsyncThunk<Article, UpdateArticlesParams>(
  'project/updateArticle',
  async ({ reject, resolve,id,tags,...article }) => {
    const { data } = await request<Article>(
      `/api/v1/articles/${id}`,
      {
        method: 'PUT',
        data: { article,tags },
      },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);


export const removeArticle = createAsyncThunk<Article, CreatArticlesParams>(
  'project/removeArticle',
  async ({ reject, resolve, ...article }) => {
    const { data } = await request<Article>(
      `/api/v1/articles/${article.id}`,
      {
        method: 'DELETE',
        data: article,
      },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);

export const favoriteArticle = createAsyncThunk<Article, QueryArticlesParams>(
  'project/favoriteArticle',
  async ({ reject, resolve, ...article }) => {
    const { data } = await request<Article>(
      `/api/v1/favorites`,
      {
        method: 'POST',
        data: { type: 'Article', id: article.id },
      },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);


export const unfavoriteArticle = createAsyncThunk<Article, QueryArticlesParams>(
  'project/unfavoriteArticle',
  async ({ reject, resolve, ...article }) => {
    const { data } = await request<Article>(
      `/api/v1/favorites/${article.id}`,
      {
        method: 'DELETE',
        data: { type: 'Article'},
      },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);



export const changeCurrentArticle = createAsyncThunk<null, Article>(
  'project/changeCurrentArticle',
  async (article,thunkAPI) => {
    thunkAPI.dispatch(projectActions.setCurrentArticle(article))
  }
);



export const queryTags = createAsyncThunk<Tag[], QueryTagParams>(
  'project/queryTags',
  async (params) => {
    const { data = [] } = await request<Tag[]>('/api/v1/tags', {
      method: 'GET',
      params,
    });
    return data;
  }
);

export const createTag = createAsyncThunk<Tag, QueryTagParams>(
  'project/createTag',
  async ({ name, reject, resolve }) => {
    const { data } = await request<Tag>(
      '/api/v1/tags',
      { method: 'POST', data: { name } },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);


export const removeTag = createAsyncThunk<Tag, QueryTagParams>(
  'project/removeTag',
  async ({ id, reject, resolve }) => {
    const { data } = await request<Tag>(
      `/api/v1/tags/${id}`,
      { method: 'DELETE'},
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);


export const queryUsers = createAsyncThunk<User[]>(
  'project/queryUsers',
  async () => {
    const { data } = await request<User[]>(
      `/api/v1/users`,
      { method: 'GET'},
    );
    return data;
  }
);



export const queryEvents = createAsyncThunk<
  Event[],
  QueryEventParams
>('project/queryEvents', async (params) => {
  const {data} = await request<Event[]>('/api/v1/events', {
    method: 'GET',
    params,
  });
  return data;
});


export const createEvent = createAsyncThunk<Event, CreateEventParams>(
  'project/createEvent',
  async ({ reject, resolve,...event }) => {
    const { data } = await request<Event>(
      '/api/v1/events',
      {
        method: 'POST',
        data: event,
      },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);


export const updateEvent = createAsyncThunk<Event, UpdateEventParams>(
  'project/updateEvent',
  async ({ reject, resolve,...event }) => {
    const { data } = await request<Event>(
      `/api/v1/events/${event.id}`,
      {
        method: 'PUT',
        data:event,
      },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);


export const removeEvent = createAsyncThunk<Event, RemoveEventParams>(
  'project/removeEvent',
  async ({ reject, resolve, ...event }) => {
    const { data } = await request<Event>(
      `/api/v1/events/${event.id}`,
      {
        method: 'DELETE',
        data: event,
      },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);


export const queryLevels = createAsyncThunk<Level[]>(
  'project/queryLevels',
  async () => {
    const { data } = await request<Level[]>(
      `/api/v1/levels`,
      { method: 'GET',params:{page: 1, size: 9999}},
    );
    return data;
  }
);

