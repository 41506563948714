import { RootState } from "@/types";
import { createSelector } from "reselect";
import { initialState } from ".";




const selectRoot = (state: RootState) => state.app || initialState;

export const selectAppInfo = createSelector([selectRoot],appState=>appState.appInfo)

export const selectPageTitle = createSelector([selectRoot],appState=>appState.title)

export const selectCurrentUser = createSelector([selectRoot],appState=>appState.currentUser)

export const selectProviders = createSelector([selectRoot],appState=>appState.providers)

export const selectAppLoading = createSelector([selectRoot],appState=>appState.appLoading)

export const selectInvite = createSelector([selectRoot],appState=>appState.invite)
