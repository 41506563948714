import { matchPath } from "react-router";

/**
 * @description 递归查询对应的路由
 * @param {String} path 当前访问地址
 * @param {Array} routes 路由列表
 * @returns array
 */
export const getRoute = (path, routes) => {
  let result = {};
  for (let item of routes) {
    if (item.path === path) return item;
    if (item.children) {
      const res = getRoute(path, item.children);
      if (Object.keys(res).length) result = res;
    }
  }
  return result;
};

export const getRouteList = (routes=[]) => {
  return routes.reduce((res,{children,path})=>{
    return res.concat([path],getRouteList(children))
  },[])
};
