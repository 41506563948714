export default [
  {
    label: '所有者',
    value: 'owner',
  },
    {
      label: '管理员',
      value: 'admin',
    },
    {
      label: '项目负责人',
      value: 'moderator',
    },
    {
      label: '项目成员',
      value: 'author',
    },
    {
      label: '禁止访问',
      value: 'banned',
    },
  ];
