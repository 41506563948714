import ToggleButton from '@/components/toggle-button';
import { Icon } from '@/icons';
import { Article } from '@/types';
import dayjs from '@/utils/dayjs';
import {
    DeleteOutlined,
    PlusOutlined,
    ShareAltOutlined,
} from '@ant-design/icons';
import { Button, Empty, Modal } from 'antd';
import VirtualList from 'rc-virtual-list';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectArticles, selectCurrentArticle
} from '../slice/selectors';
import {
    changeCurrentArticle,
    favoriteArticle,
    queryArticles,
    queryUsers,
    removeArticle,
    unfavoriteArticle
} from '../slice/thunks';
import './index.scss';

let ContainerHeight = 800;
const Sidebar = () => {
  const dispatch = useDispatch();
  const articles = useSelector(selectArticles);
  const article = useSelector(selectCurrentArticle);
  const [params, setParams] = useState({
    page: 1,
    size: 20,
  });

  const [selectedKey, setSelectedKey] = useState(article?.id);

  useEffect(() => {
    fetchArticles(params);
    ContainerHeight = document.getElementById('article-list')?.clientHeight;
    dispatch(queryUsers());
  }, []);

  const fetchArticles = useCallback(
    (params) => {
      dispatch(queryArticles(params));
    },
    [dispatch]
  );

  const onScroll = useCallback((e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      ContainerHeight
    ) {
      if (articles.data.length < articles.count) {
        setParams((pre) => {
          const newState = { ...pre, page: pre.page + 1 };
          fetchArticles(newState);
          return newState;
        });
      }
    }
  },[fetchArticles,params]);

  const handleChangeArticle = useCallback(
    (item) => {
      dispatch(changeCurrentArticle(item));
      setSelectedKey(item?.id);
    },
    [dispatch]
  );

  const handleFavoriteArticle = useCallback(
    (item) => {
      if (item?.favorite) {
        dispatch(unfavoriteArticle(item));
      } else {
        dispatch(favoriteArticle(item));
      }
    },
    [dispatch]
  );

  const handleDeleteArticle = useCallback(
    (item) => {
      dispatch(removeArticle(item));
    },
    [dispatch]
  );

  return (
    <div className="h-[calc(100vh-50px)] bg-white divide-y">
      <div className="py-2 flex items-center justify-center">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          className="w-[90%]"
          onClick={(e) => handleChangeArticle({})}
        >
          新建文章
        </Button>
      </div>
      <div className="article-list h-[calc(100vh-100px)]" id="article-list">
        {articles?.data.length ? (
          <VirtualList
            data={articles?.data}
            height={ContainerHeight}
            itemHeight={100}
            itemKey="id"
            onScroll={onScroll}
          >
            {(item: Article) => (
              <div
                className={`relative group item  ${
                  selectedKey == item.id ? 'selected' : ''
                }`}
                onClick={(e) => {
                  handleChangeArticle(item);
                }}
                onMouseEnter={(e) => {}}
              >
                <div className=" absolute right-0">
                  <ToggleButton
                    tipProps={{ title: '点击分享' }}
                    buttonProps={{
                      type: 'link',
                      size: 'small',
                      onClick: () => console.log(item),
                    }}
                    hoverable={selectedKey === item.id}
                  >
                    <ShareAltOutlined
                      style={{
                        fontSize: '16px',
                        width: '16px',
                        height: '16px',
                        color: 'white',
                      }}
                    />
                  </ToggleButton>
                  <ToggleButton
                    tipProps={{ title: '点击收藏后，可在文件夹里快速找到' }}
                    buttonProps={{
                      type: 'link',
                      size: 'small',
                      onClick: () => handleFavoriteArticle(item),
                    }}
                    hoverable={selectedKey === item.id}
                  >
                    <Icon
                      type={item?.favorite ? 'icon-star-full' : 'icon-star'}
                      style={{
                        fontSize: '16px',
                        width: '16px',
                        height: '16px',
                        color: 'white',
                      }}
                    />
                  </ToggleButton>
                  <ToggleButton
                    tipProps={{ title: '删除文章' }}
                    buttonProps={{
                      type: 'link',
                      size: 'small',
                      onClick: () =>
                        Modal.confirm({
                          title: '删除文章？',
                          onOk() {
                            handleDeleteArticle(item);
                          },
                          okButtonProps: {
                            type: 'primary',
                          },
                        }),
                    }}
                    hoverable={selectedKey === item.id}
                  >
                    <DeleteOutlined
                      style={{
                        fontSize: '16px',
                        width: '16px',
                        height: '16px',
                        color: 'white',
                      }}
                    />
                  </ToggleButton>
                </div>
                <div className="item-title group-hover:text-white ">
                  {item.title}
                </div>
                <div className="item-time group-hover:text-white">
                  {dayjs(item.created_at).format('YY/M/D HH:mm')}
                </div>
                <div className="item-content group-hover:text-white">
                  {!item.content
                    ? '无内容'
                    : item.content.replace(/<\/?.+?>/g, '')}
                </div>
              </div>
            )}
          </VirtualList>
        ) : (
          <Empty description={'还没有文章'} />
        )}
      </div>
    </div>
  );
};

export default Sidebar;
