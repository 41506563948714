import Loading from '@/components/loading';
import Collect from '@/pages/collect';
import Error403 from '@/pages/error/403';
import Error404 from '@/pages/error/404';
import Error500 from '@/pages/error/500';
import History from '@/pages/history';
import Invites from '@/pages/invites';
import Articles from '@/pages/projects/articles';
import Events from '@/pages/projects/events';
import Labels from '@/pages/projects/labels';
import Settings from '@/pages/settings';
import Team from '@/pages/teams';
import EditPassword from '@/pages/users/edit-password';
import ForgetPassword from '@/pages/users/forget-password';
import Login from '@/pages/users/login';
import Register from '@/pages/users/register';
import SsoCallback from '@/pages/users/sso-callback';
import Welcome from '@/pages/welcome';
import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

type LazyComponentProps = {
  component: string;
};

export const LazyComponent = ({ component, ...props }: LazyComponentProps) => {
  const LazyChildren = useMemo(
    () =>
      React.lazy(() => {
        let modules = {
          ...import.meta.glob('../layouts/*.tsx'),
          ...import.meta.glob('../layouts/*/*.tsx'),
        };
        console.log(component, modules, modules[`../${component}.tsx`]);
        return modules[`../${component}.tsx`]();
      }),
    [component]
  );
  return (
    <React.Suspense fallback={<Loading />}>
      <LazyChildren {...props} />
    </React.Suspense>
  );
};

export const getRoutes = () => [
  {
    path: '/',
    element: <Navigate to="/welcome" replace />,
  },
  {
    element: <LazyComponent component={'layouts/SimpleLayout'} />,
    children: [
      {
        index: true,
        path: '/users/login',
        element: <Login appLoading={false} />,
        meta: {
          auth: false,
          title: '登录',
        },
      },
      {
        path: '/users/register',
        element: <Register appLoading={false} />,
        meta: {
          auth: false,
          title: '注册',
        },
      },
      {
        path: '/users/forget-password',
        element: <ForgetPassword appLoading={false} />,
        meta: {
          auth: false,
          title: '忘记密码',
        },
      },
      {
        path: '/users/edit-password/:reset_password_token',
        element: <EditPassword appLoading={false} />,
        meta: {
          auth: false,
          title: '修改密码',
        },
      },
      {
        path: '/users/sso-callback',
        element: <SsoCallback />,
        meta: {
          auth: false,
          title: '回调页面',
        },
      },
      {
        path: '/invites/:token',
        meta: {
          auth: false,
          title: '邀请页面',
        },
        element: <Invites />,
      },
    ],
  },
  {
    element: <LazyComponent component={'layouts/BaseLayout'} />,
    children: [
      {
        index: true,
        path: '/welcome',
        element: <Welcome />,
        meta: {
          auth: true,
          title: '欢迎页面',
        },
      },
      {
        path: '/settings',
        element: <Settings />,
        meta: {
          auth: true,
          title: '个人设置',
        },
      },
      {
        path: '/teams/:id',
        element: <Team />,
        meta: {
          auth: true,
          title: '团队',
        },
      },

      {
        path: '/collect',
        element: <Collect />,
        meta: {
          auth: true,
          title: '我的收藏',
        },
      },

      {
        path: '/history',
        element: <History />,
        meta: {
          auth: true,
          title: '最近访问',
        },
      },
    ],
  },
  {
    path: '/teams/:teamId/projects/:projectId',
    meta: {
      auth: true,
      title: '项目列表',
    },
    element: <LazyComponent component={'layouts/ProjectLayout'} />,
    children: [
      {
        path: 'events',
        element: <Events />,
      },
      {
        path: 'articles',
        element: <Articles />,
      },
      {
        path: 'labels',
        element: <Labels />,
      },
    ],
  },
  {
    path: '/500',
    element: <Error500 />,
  },
  {
    path: '/404',
    element: <Error404 />,
  },
  {
    path: '/403',
    element: <Error403 />,
  },
  {
    path: '*',
    element: <Navigate to="/404" />,
  },
];
