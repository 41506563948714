import Scheduler from '@/components/scheduler';
import { useAuthorize } from '@/hooks/useAuthorize';
import { useSelector } from 'react-redux';
import Statistic from './Statistic';
import './index.scss';
import { selectEvents, selectProjectLevels, selectUsers } from '../projects/slice/selectors';
import Authorized from '@/components/authorized';
import { useEffect, useState } from 'react';

const Welcome = ({}) => {
  const { currentUser } = useAuthorize();
  const users = useSelector(selectUsers);
  const events = useSelector(selectEvents);
  const levels = useSelector(selectProjectLevels)

  const data = []


  useEffect(()=>{
    const eventSource = new EventSource('/events',{withCredentials: true})
  // 正常的EventSource，我们只关心以下三个事件
  /*
  * open：订阅成功（和后端连接成功）
  */
  eventSource.addEventListener("open", function(e) {
    console.log('open successfully')
  })
  /*
  * message：后端返回信息，格式可以和后端协商
  */
  eventSource.addEventListener("message", function(e) {
    console.log("recieve message",e)
    if(true){
      eventSource.close();
    }
  })
  /*
  * error：错误（可能是断开，可能是后端返回的信息）
  */
  eventSource.addEventListener("error", function(err) {
    console.log(err)

    eventSource.close();
    // 类似的返回信息验证，这里是实例
    err && err.status === 401 && console.log('not authorized')
  })
  },[])

  return (
    <div className=' h-screen w-full dark:bg-slate-900 bg-white dark:text-white '>
      <div className='flex flex-col border rounded-lg dark:border-slate-700'>
      <Authorized denied={<></>} authority={currentUser.is_admin}>
        <div className='dark:text-white text-lg font-bold py-2 px-5'>团队统计</div>

          <Statistic />
        </Authorized>
      </div>
      <div>
        <Scheduler
          events={events}
          hours={[9, 18]}
          createEvents={(e) => {}}
          multiple={currentUser.is_admin}
          levels={levels}
          users={users}
          currentUser={currentUser.id}
        />
      </div>
    </div>
  );
};

export default Welcome;
