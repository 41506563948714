import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, message, Popconfirm, Tooltip } from 'antd';
import { useAuthorize } from '@/hooks/useAuthorize';
import { selectAppLoading, selectCurrentTeam } from '../slice/selectors';
import { modifyTeam } from '../slice/thunks';
import LevelPanel from './LevelPanel';
import Base from './Base';
import { useParams } from 'react-router';

const Settings = memo(() => {
  const {id} = useParams()

  return (
    <div className="flex flex-col">
      <div className="py-8">
        <Base key={id}/>
      </div>
      <div className="py-8">
        <LevelPanel key={id}/>
      </div>
    </div>
  );
});

export default Settings;
