import { useCallback, useState } from 'react';
import { Input, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

interface TagsProps {
  tags?: string[];
  onChange?: (value: string[]) => void;
  className?: string;
}

const Tags = ({ value: defaultValue = [], onChange, className }: TagsProps) => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState('');
  const forMap = (tag: string, index: number) => {
    const tagElement = (
      <Tag key={index} color="geekblue">
        {tag}
      </Tag>
    );

    return (
      <span className=" inline-block" key={index}>
        {tagElement}
      </span>
    );
  };
  const tagChild = defaultValue.map(forMap);
  const handleInput = useCallback(
    (e) => {
      if (value && defaultValue.indexOf(value) === -1) {
        onChange?.([...defaultValue, value]);
      }
      setVisible(false);
      setValue('');
    },
    [value]
  );
  return (
    <div className={`w-full ${className}`}>
      {tagChild}
      {visible ? (
        <Input
          size="small"
          className="max-w-[100px]"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleInput}
          onPressEnter={handleInput}
        />
      ) : (
        <Tag onClick={(e) => setVisible(true)} color="#4F46E5">
          <PlusOutlined /> 添加标签
        </Tag>
      )}
    </div>
  );
};

export default Tags;
