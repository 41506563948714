import React,{ memo } from 'react';
import './index.scss';
import { LayoutMode } from './types';

interface LayoutProps{
  mode: LayoutMode;
  children: React.ReactElement;
}


const Layout:React.FC<LayoutProps> = memo(({ mode, children, ...props }) => {
  return (
    <div
      className={`basic-layout ${
        mode === LayoutMode.ROW ? 'row-mode' : 'col-mode'
      }`}
      {...props}
    >
      {children}
    </div>
  );
});

export default Layout;
