import { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectAppLoading } from '@/slice/selectors';
import actionCable from 'actioncable';

interface CableAppProps {
  cable: any;
}

let CableApp = {} as CableAppProps;

type AppContextType = CableAppProps & {
  appLoading: boolean;
};

export const AppContext = createContext<AppContextType | null>(null);

export const AppProvider = ({ children }) => {
  CableApp.cable = actionCable.createConsumer('/cable');

  const appLoading = useSelector(selectAppLoading);

  return (
    <AppContext.Provider
      value={{
        cable: CableApp.cable,
        appLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
