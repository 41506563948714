import { Fragment, useCallback, useContext, useEffect } from 'react';
import { Typography } from 'antd';
import { isWithinInterval } from '../utils/date-fns';
import dayjs from '@/utils/dayjs';
import Cell from '../Cell';
import { SchedulerContext, SchedulerContextProps } from '../context';
import Item from '../events/Item';
import TodayItem from '../events/TodayItem';
import Grid from '../Grid';
import { defaultWeekDays } from '../types';
import { dayWithMinute, filterHeaderEvents, filterTodayEvents } from '../utils';
import { WithResources } from '../WithResources';

const Week = () => {
  const {
    hours: conditionHours,
    currentDate,
    events,
    handleGotoDay,
    fetchEvents,
    currentUser,
  } = useContext(SchedulerContext);
  const [startDate, endDate, hours] = dayWithMinute({
    currentDate,
    hours: conditionHours,
  });

  const weekStart = startDate.startOf('week');
  const weekEnd = startDate.endOf('week');
  let dayList = defaultWeekDays.map((d) => weekStart.add(d, 'day'));

  const ITEM_HEIGHT = 35;

  const CELL_HEIGHT = 65;
  const MINUTE_HEIGHT = Math.ceil(CELL_HEIGHT) / 60;

  const diffTime = (start, end) => {
    return dayjs.duration(end.diff(start)).get('day') + 1;
  };

  const handleFetchEvents = useCallback(async () => {
    await fetchEvents?.({
      user_id: currentUser,
      start: weekStart.format("YYYY-MM-DD 00:00:00"),
      end:  weekEnd.format("YYYY-MM-DD 23:59:59"),
    });
  }, [currentUser, currentDate, fetchEvents]);

  useEffect(() => {
    handleFetchEvents();
  }, [currentUser, currentDate, fetchEvents]);

  const renderHeaderEvents = (allEvents, today) => {
    const isFirstDayInWeek = weekStart.isSame(today);
    const todayEvents = filterHeaderEvents(allEvents, dayList);

    const multiDays = todayEvents
      .filter((e) => {
        const start = dayjs(e.start).startOf('day');
        const result = start.isBefore(weekStart)
          ? isFirstDayInWeek
          : start.isSame(today);
        return result;
      })
      .sort((a, b) => a - b);
    return (
      <div
        className="block_col"
        // style={{ height: ITEM_HEIGHT * todayEvents.length }}
      >
        {multiDays.map((event, i) => {
          const start = dayjs(event.start);
          const end = dayjs(event.end);
          const hasPrev = start.isBefore(dayjs(weekStart).startOf('day'));
          const hasNext = end.isAfter(dayjs(weekEnd).endOf('day'));

          const eventSize = diffTime(
            hasPrev ? weekStart : start,
            hasNext ? weekEnd : end
          );

          const prevNextEvents = allEvents.filter((e) =>
            isFirstDayInWeek
              ? false
              : e.id !== event.id &&
                isWithinInterval(today, {
                  start: dayjs(e.start),
                  end: dayjs(e.end),
                })
          );

          let index = i;
          if (prevNextEvents.length) {
            index += prevNextEvents.length;
          }

          return (
            <div
              key={i}
              className="multiple-events"
              style={{
                top: index * ITEM_HEIGHT,
                width: `${100 * eventSize}%`,
                overflowX: 'hidden',
                borderRadius: 5
              }}
            >
              <Item  key={i} event={event} header hasPrev={hasPrev} hasNext={hasNext} />
            </div>
          );
        })}
      </div>
    );
  };

  const renderTable = () => {
    const filterEvents = filterHeaderEvents(events, dayList);
     // const headerHeight = ITEM_HEIGHT * filterEvents.length + 45;
    const headerHeight = ITEM_HEIGHT * filterEvents.length + 55;
    return (
      <>
        <Grid sticky days={7} week>
          <span className="cell"></span>
          {dayList.map((d, i) => (
            <span
              key={i}
              className="cell cell__header"
              style={{ height: headerHeight, minHeight: 45 }}
            >
              <div
                style={{
                  color: dayjs(d).isToday() ? '#7d72f2' : 'black',
                  fontWeight: dayjs(d).isToday() ? 'bolder' : '',
                }}
                className=" select-none cursor-pointer"
                onClick={(e) => handleGotoDay?.(d)}
              >
                {d.format('ddd')}
                <br></br>
                {d.format('MM-DD')}
              </div>
              {renderHeaderEvents(events, d)}
            </span>
          ))}
        </Grid>
        <Grid days={7} week>
          {hours.map((h, i) => {
            return (
              <Fragment key={i}>
                <span
                  className="cell cell__header cell__time"
                  style={{ height: 66 }}
                >
                  <Typography>{h.format('HH:mm A')}</Typography>
                </span>
                {dayList.map((d, ii) => {
                  const start = d.add(h.get('hours'), 'hours');
                  const end = start.add(60, 'minute');
                  return (
                    <span className="cell" key={ii}>
                      {i === 0 && (
                        <TodayItem
                          key={i}
                          events={filterTodayEvents(events, d)}
                          today={d.add(conditionHours?.[0], 'hour')}
                          minuteHeight={MINUTE_HEIGHT}
                          startHour={conditionHours?.[0]}
                        />
                      )}
                      <Cell
                        style={{ height: CELL_HEIGHT }}
                        start={start}
                        end={end}
                        day={d}
                        id={`week-${i}-${ii}`}
                      />
                    </span>
                  );
                })}
              </Fragment>
            );
          })}
        </Grid>
      </>
    );
  };

  return <WithResources>{renderTable()}</WithResources>;
};

export default Week;
