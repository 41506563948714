import MarkdownEditor from '@/components/markdown-editor';
import dayjs from '@/utils/dayjs';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Tag
} from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { useCallback, useContext, useEffect } from 'react';
import { SchedulerContext } from '../context';

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const Editor = ({ onCancel, ...props }) => {
  const {
    currentItem,
    start,
    users,
    hours,
    levels = [],
    createEvents,
    currentUser,
    handleSetState,
    ...initialState
  } = useContext(SchedulerContext);
  const [form] = Form.useForm();
  const title = currentItem ? '修改任务' : '添加任务';

  useEffect(() => {
    if (currentItem) {
      form.setFieldsValue({
        ...currentItem,
        start: dayjs(currentItem.start),
        end: dayjs(currentItem.end),
      });
    }
  }, [currentItem]);

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return (
      (current && current < start) || current < form.getFieldValue('start')
    );
  };

  const disabledTime: RangePickerProps['disabledTime'] = () => {
    // Can not select days before today and today
    return {
      disabledHours() {
        return [...range(0, hours[0]), ...range(hours[1] + 1, 24)];
      },
    };
  };

  const handleSubmit = useCallback(async () => {
    await form.validateFields();
    await createEvents?.({
      ...currentItem,
      ...form.getFieldsValue(),
      user_id: currentUser,
    });
    handleCancel();
  }, [form, createEvents, currentItem]);

  const handleCancel = useCallback(async () => {
    await onCancel?.();
    await form.resetFields();
  }, [form, onCancel]);


  return (
    <Modal
      {...props}
      title={<div className='flex items-center justify-between'>
        <div>{title}</div>
        <Space >
          <Button onClick={handleCancel} >取消</Button>
          <Button type='primary' onClick={handleSubmit}>保存</Button>
        </Space>
      </div>}
      footer={null}
      destroyOnClose
      closable={false}
      maskClosable
      width="50%"
    >
      <Form form={form} layout="vertical" preserve={false}>
            <Row>
              <Col span={16}>
                <Form.Item
                  name="title"
                  rules={[{ required: true, message: '请输入任务名称' }]}
                >
                  <Input placeholder="任务名称" />
                </Form.Item>
              </Col>
              <Col span={6} offset={1}>
                <Form.Item
                  // label="标签"
                  name="level_id"
                  rules={[{ required: true, message: '请选择任务标签' }]}
                >
                  <Select placeholder="任务标签" optionLabelProp="label">
                    {levels?.map((level) => (
                      <Select.Option
                        value={level.id}
                        label={level.label}
                        key={level.id}
                      >
                        <Space>
                          <Tag color={level.color}>{level.label}</Tag>
                        </Space>
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="body">
              <MarkdownEditor
                className="max-h-[400px] overflow-auto z-10"
                options={{ placeholder: '任务内容' }}
                users={users}
              />
            </Form.Item>

            <Row>
              <Col span={11}>
                <Form.Item
                  // label="开始时间"
                  name="start"
                  rules={[{ required: true, message: '请输入任务开始时间' }]}
                  initialValue={props.start}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm"
                    showTime={{
                      hideDisabledOptions: true,
                      format: 'HH:mm',
                    }}
                    placeholder="任务开始时间"
                    style={{ width: '100%' }}
                    disabledDate={disabledDate}
                    disabledTime={disabledTime}
                  />
                </Form.Item>
              </Col>
              <Col span={11} offset={1}>
                <Form.Item
                  // label="结束时间"
                  name="end"
                  rules={[{ required: true, message: '请输入任务结束时间' }]}
                >
                  <DatePicker
                    format="YYYY-MM-DD HH:mm"
                    showTime={{ hideDisabledOptions: true, format: 'HH:mm' }}
                    style={{ width: '100%' }}
                    placeholder="任务结束时间"
                    disabledDate={disabledDate}
                    disabledTime={disabledTime}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
    </Modal>
  );
};

export default Editor;
