import React, { Fragment, memo, ReactNode, useRef } from 'react';
import Split from 'react-split';
import './index.scss';

interface SpiltPanelOptions {
  split: 'x' | 'y' | 'both';
  minWidths: number[];
  maxWidths: number[];
}

interface SplitPanelProps {
  children: ReactNode;
  options: SpiltPanelOptions;
  className?: string;
}

type SplitType = {
  split: {
    setSizes: (number: number[]) => void;
  };
};

const SplitPanel = memo(({ children, options, className }: SplitPanelProps) => {
  const splitRef = useRef(null);

  return (
    <Split
      ref={splitRef}
      className={`split ${className}`}
      sizes={options?.minWidths}
      minSize={200}
      direction={options?.split === 'x' ? 'horizontal' : 'vertical'}
      gutterSize={10}
      cursor="col-resize"
      onDrag={(sizes) => {
        if (sizes[0] > options.maxWidths[0]) {
          if (splitRef.current as SplitType) {
            splitRef.current.split.setSizes(options?.maxWidths);
          }
        }
      }}
    >
      {children}
    </Split>
  );
});

SplitPanel.Item = memo((props) => {
  return <Fragment {...props}>{props.children}</Fragment>;
});

export default SplitPanel;
