export default [
    "emoji",
    "headings",
    "bold",
    "italic",
    "strike",
    "link",
    "|",
    "list",
    "ordered-list",
    "check",
    "outdent",
    "indent",
    "|",
    "quote",
    "line",
    "code",
    "inline-code",
    "insert-before",
    "insert-after",
    "|",
    "upload",
    "record",
    "table",
    "|",
    "undo",
    "redo",
    "|",
    'export',
    // "|",
    // "fullscreen",
    // "edit-mode",
    // {
    //     name: "more",
    //     toolbar: [
    //         "both",
    //         "code-theme",
    //         "content-theme",
    //         "export",
    //         "outline",
    //         "preview",
    //         "devtools",
    //         "info",
    //         "help",
    //     ],
    // },
].map(toolbar=>{
    if(toolbar !== '|'){
        return {name: toolbar,tipPosition: 'se'}
    }
    return toolbar
})
