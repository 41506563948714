import { Team, User } from '@/types';
import { useInjectReducer } from '@/utils/@reduxjs/injectReducer';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createTag,
  createTeam,
  forgotPasword,
  getAppInfo,
  getCurrentUser,
  getProviders,
  joinInvite,
  queryTags,
  queryTeams,
  removeTag,
  resetPassword,
  showInvite,
  signIn,
  signUp
} from './thunks';
import { AppState } from './types';

export const initialState: AppState = {
  appLoading: false,
  appInfo: null,
  currentUser: null,
  title: null,
  providers: [],
  currentTeam: undefined,
  currentProject: undefined,
  invite: null,
  tags:[],
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    savePageTitle(state, action: PayloadAction<{ title: string }>) {
      state.title = action.payload.title;
    },
    updateCurrentUser(state, { payload }: PayloadAction<User | null>) {
      state.currentUser = payload;
    },
  },
  extraReducers(builder) {
    // version
    builder.addCase(getAppInfo.fulfilled, (state, action) => {
      state.appInfo = action.payload;
    });
    //currentUser
    builder.addCase(getCurrentUser.rejected, (state, action) => {
      state.currentUser = null;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.currentUser = action.payload;
      state.currentTeam = action.payload.teams.find((team) => team.primary);
    });
    //providers
    builder.addCase(getProviders.fulfilled, (state, action) => {
      state.providers = action.payload;
    });
    //sign in
    builder.addCase(signIn.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.appLoading = false;
      state.currentUser = action.payload;
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.appLoading = false;
    });


    //create team
    builder.addCase(createTeam.pending, (state, action) => {
      state.appLoading = true;
    });

    builder.addCase(createTeam.fulfilled, (state, action) => {
      state.appLoading = false;
    });

    builder.addCase(createTeam.rejected, (state, action) => {
      state.appLoading = false;
    });

    //sign up
    builder.addCase(signUp.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.appLoading = false;
    });

    builder.addCase(signUp.rejected, (state, action) => {
      state.appLoading = false;
    });

    //forgot password
    builder.addCase(forgotPasword.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(forgotPasword.fulfilled, (state, action) => {
      state.appLoading = false;
    });
    builder.addCase(forgotPasword.rejected, (state, action) => {
      state.appLoading = false;
    });

    //reset password
    builder.addCase(resetPassword.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.appLoading = false;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.appLoading = false;
    });


    //show invite
    builder.addCase(showInvite.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(showInvite.fulfilled, (state, action) => {
      state.appLoading = false;
      state.invite = action.payload;
    });
    builder.addCase(showInvite.rejected, (state, action) => {
      state.appLoading = false;
    });


    //join invite
    builder.addCase(joinInvite.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(joinInvite.fulfilled, (state, action) => {
      state.appLoading = false;
    });
    builder.addCase(joinInvite.rejected, (state, action) => {
      state.appLoading = false;
    });
  },
});

export const { actions: appActions, reducer } = slice;

export const useAppSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
