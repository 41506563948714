import Mark from '@/assets/images/mark.svg';
import { forgotPasword } from '@/slice/thunks';
import { Button, Form, Input, message } from "antd";
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import SharedLink from "./shard-link";


const ForgetPassword = ({appLoading}) =>{
    const dispatch = useDispatch();

    const handleForgotPassword =useCallback( (values) =>{
        dispatch(forgotPasword({user: values,reject(error) {
            message.error('邮件发送失败,请联系管理员')
        },resolve(value) {
            message.success(value)
        },}))
    },[dispatch])

    return <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img className="mx-auto h-10" src={Mark} alt="国立软件" />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">忘记密码</h2>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <Form layout="vertical" onFinish={handleForgotPassword}>
                <Form.Item label="邮箱" name="email" rules={[{ required: true }]}>
                    <Input placeholder="请输入邮箱"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" className="w-full" htmlType='submit' loading={appLoading}>发送至邮箱</Button>
                </Form.Item>
            </Form>
            <SharedLink/>
        </div>
    </div>
}


export default ForgetPassword;
