import { Project, Team } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer } from '@/utils/@reduxjs/injectReducer';
import {
  createInvite,
  createLevel,
  createProject,
  favoriteProject,
  modifyLevel,
  modifyProject,
  modifyTeam,
  queryLevels,
  queryProjects,
  queryTeamUsers,
  removeLevel,
  removeProject,
  resetInvite,
  switchPrimaryProject,
  switchPrimaryTeam,
  unfavoriteProject,
} from './thunks';
import { TeamState } from './types';

export const initialState: TeamState = {
  appLoading: false,
  currentTeam: null,
  currentProject: null,
  projects: { data: [], count: 0 },
  members: { data: [], count: 0 },
  levels: { data: [], count: 0 },
};

const slice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    switchCurrentTeam(state, { payload }: PayloadAction<Team | undefined>) {
      state.currentTeam = payload;
      // state.members = {data: [],count: 0}
      // state.projects = {data: [],count: 0}
      // state.levels = {data: [],count: 0}
    },

    switchCurrentProject(
      state,
      { payload }: PayloadAction<Project | undefined>
    ) {
      state.currentProject = payload;
    },
  },
  extraReducers(builder) {
    //query projects
    builder.addCase(queryProjects.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(queryProjects.fulfilled, (state, action) => {
      state.appLoading = false;
      state.projects = action.payload;
    });
    builder.addCase(queryProjects.rejected, (state, action) => {
      state.appLoading = false;
    });

    //create project
    builder.addCase(createProject.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(createProject.fulfilled, (state, action) => {
      state.appLoading = false;
      state?.projects?.data?.unshift?.(action.payload);
      state!.projects!.count += 1;
    });
    builder.addCase(createProject.rejected, (state, action) => {
      state.appLoading = false;
    });

    //modify project
    builder.addCase(modifyProject.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(modifyProject.fulfilled, (state, action) => {
      state.appLoading = false;
      state.projects.data = state?.projects?.data?.map((row) => {
        if (row.id === action.payload.id) {
          return { ...row, ...action.payload };
        }
        return row;
      });
    });

    builder.addCase(modifyProject.rejected, (state, action) => {
      state.appLoading = false;
    });

    //remove project
    builder.addCase(removeProject.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(removeProject.fulfilled, (state, action) => {
      state.appLoading = false;
      state.projects.data = state?.projects?.data?.filter(
        (row) => row.id !== action.payload.id
      );
      state.projects.count -= 1;
    });

    builder.addCase(removeProject.rejected, (state, action) => {
      state.appLoading = false;
    });

    //favorite project
    builder.addCase(favoriteProject.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(favoriteProject.fulfilled, (state, action) => {
      state.appLoading = false;
      state.projects.data = state?.projects?.data?.map((row) => {
        if (row.id === action.payload.id) {
          return { ...row, favorite: true };
        }
        return row;
      });
    });

    builder.addCase(favoriteProject.rejected, (state, action) => {
      state.appLoading = false;
    });

    //unfavorite project
    builder.addCase(unfavoriteProject.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(unfavoriteProject.fulfilled, (state, action) => {
      state.appLoading = false;
      state.projects.data = state?.projects?.data?.map((row) => {
        if (row.id === action.payload.id) {
          return { ...row, favorite: false };
        }
        return row;
      });
    });

    builder.addCase(unfavoriteProject.rejected, (state, action) => {
      state.appLoading = false;
    });

    //switch current project
    builder.addCase(switchPrimaryProject.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(switchPrimaryProject.fulfilled, (state, action) => {
      state.appLoading = false;
      state.projects.data = state?.projects?.data?.map((row) => ({
        ...row,
        primary: row.id === action.payload.id,
      }));
    });
    builder.addCase(switchPrimaryProject.rejected, (state, action) => {
      state.appLoading = false;
    });

    //switch current team
    builder.addCase(switchPrimaryTeam.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(switchPrimaryTeam.fulfilled, (state, action) => {
      state.appLoading = false;
    });
    builder.addCase(switchPrimaryTeam.rejected, (state, action) => {
      state.appLoading = false;
    });

    //query team users
    builder.addCase(queryTeamUsers.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(queryTeamUsers.fulfilled, (state, action) => {
      state.appLoading = false;
      state.members = action.payload;
    });
    builder.addCase(queryTeamUsers.rejected, (state, action) => {
      state.appLoading = false;
    });

    //create invite
    builder.addCase(createInvite.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(createInvite.fulfilled, (state, action) => {
      state.appLoading = false;
    });
    builder.addCase(createInvite.rejected, (state, action) => {
      state.appLoading = false;
    });

    //reset invite
    builder.addCase(resetInvite.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(resetInvite.fulfilled, (state, action) => {
      state.appLoading = false;
    });
    builder.addCase(resetInvite.rejected, (state, action) => {
      state.appLoading = false;
    });

    //modify team title
    builder.addCase(modifyTeam.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(modifyTeam.fulfilled, (state, action) => {
      state.appLoading = false;
      state.currentTeam = action.payload;
    });
    builder.addCase(modifyTeam.rejected, (state, action) => {
      state.appLoading = false;
    });

    //query levels
    builder.addCase(queryLevels.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(queryLevels.fulfilled, (state, action) => {
      state.appLoading = false;
      if (action.meta.arg.page === 1) {
        state.levels = {
          count: action.payload.count,
          data: Object.values(
            [...action.payload.data].reduce(
              (acc, obj) => ({ ...acc, [obj.id]: obj }),
              {}
            )
          )
        };
      } else {
        state.levels = {
          count: state.levels.count + action.payload.count,
          data: Object.values(
            [...state.levels.data, ...action.payload.data].reduce(
              (acc, obj) => ({ ...acc, [obj.id]: obj }),
              {}
            )
          )
        };
      }

      state.levels.count = action.payload.count;
    });
    builder.addCase(queryLevels.rejected, (state, action) => {
      state.appLoading = false;
    });

    //create level
    builder.addCase(createLevel.pending, (state, action) => {
      state.appLoading = true;
    });

    builder.addCase(createLevel.fulfilled, (state, action) => {
      state.appLoading = false;
      state?.levels?.data?.unshift(action.payload);
      state.levels.count += 1;
    });

    builder.addCase(createLevel.rejected, (state, action) => {
      state.appLoading = false;
    });

    // modify level
    builder.addCase(modifyLevel.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(modifyLevel.fulfilled, (state, action) => {
      state.appLoading = false;
      state.levels.data = state?.levels?.data?.map((row) => {
        if (row.id === action.payload.id) {
          return { ...row, ...action.payload };
        }
        return row;
      });
    });

    builder.addCase(modifyLevel.rejected, (state, action) => {
      state.appLoading = false;
    });

    //remove project
    builder.addCase(removeLevel.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(removeLevel.fulfilled, (state, action) => {
      state.appLoading = false;
      state.levels.data = state?.levels?.data?.filter(
        (row) => row.id !== action.payload.id
      );
      state.levels.count -= 1;
    });

    builder.addCase(removeLevel.rejected, (state, action) => {
      state.appLoading = false;
    });
  },
});

export const { actions: teamActions, reducer } = slice;

export const useTeamSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
