import { useState } from 'react';
import { Checkbox, Space } from 'antd';

const CheckItem = ({ renderItem, item }) => {
  return (
    <div className="flex items-center justify-between p-3 w-full">
        <div>{renderItem && renderItem(item)}</div>
        <Checkbox value={item?.value} />
      </div>
  );
};

const CheckGroup = ({ title, renderItem, options, ...props }) => {
  const [checkAll, setCheckAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkedList, setCheckedList] = useState(props?.value || []);

  const onCheckAllChange = (e) => {
    const list = options.map((item) => item.value);
    setCheckedList(e.target.checked ? list : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);

    props?.onChange(list);
  };

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < options.length);
    setCheckAll(list.length === options.length);

    props?.onChange(list);
  };

  return (
    <div className="flex w-full flex-col divide-y">
      <div className="flex items-center justify-between p-3">
        <div>
          {title}
          <span className=" text-indigo-400"> ({checkedList.length})</span>
        </div>
        <div className="flex justify-between w-[50px]">
          <span>全选</span>
          <Checkbox
            indeterminate={indeterminate}
            checked={checkAll}
            onChange={onCheckAllChange}
          />
        </div>
      </div>
      <div className='h-[300px] overflow-auto'>
      <Checkbox.Group
        className="flex w-full flex-col divide-y"
        onChange={onChange}
        value={checkedList}
      >
        {options.map((item) => (
          <CheckItem renderItem={renderItem} item={item} key={item.value} />
        ))}
      </Checkbox.Group>
      </div>
    </div>
  );
};

export default CheckGroup;
