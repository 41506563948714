import React, { memo, useEffect, useState } from 'react';
import { message } from 'antd';
import { User } from '@/types';
import { DirectUpload } from '@rails/activestorage';
import Cookies from 'js-cookie';
import Vditor from 'vditor';
import 'vditor/dist/index.css';
import useDebounce from '@/hooks/useDebounce';
import './index.scss';
import toolbars from './toolbars';

type MarkdownEditorProps = {
  value?: null | string;
  style?: React.CSSProperties;
  className?: string;
  options?: any;
};
const MarkdownPreview: React.FC<MarkdownEditorProps> = memo(
  ({ value, style, className }) => {
    const initOutline = () => {
      const headingElements = [];
      let preview = document.getElementById('vditor-preview');
      Array.from(preview.children).forEach((item) => {
        if (
          item.tagName.length === 2 &&
          item.tagName !== 'HR' &&
          item.tagName.indexOf('H') === 0
        ) {
          headingElements.push(item);
        }
      });

      let toc = [];
      window.addEventListener(
        'scroll',
        (e) => {
          const scrollTop = e.target.offsetTop;
          console.log(window.scrollY, scrollTop);
          toc = [];
          headingElements.forEach((item) => {
            toc.push({
              id: item.id,
              offsetTop: item.offsetTop,
            });
          });

          const currentElement = document.querySelector(
            '.vditor-outline__item--current'
          );
          for (let i = 0, iMax = toc.length; i < iMax; i++) {
            if (scrollTop < toc[i].offsetTop - 30) {
              if (currentElement) {
                currentElement.classList.remove(
                  'vditor-outline__item--current'
                );
              }
              let index = i > 0 ? i - 1 : 0;
              document
                .querySelector('span[data-target-id="' + toc[index].id + '"]')
                .classList.add('vditor-outline__item--current');
              break;
            }
          }
        },
        true
      );
    };

    useEffect(() => {
      Vditor.preview(document.getElementById('vditor-preview'), value, {
        speech: {
          enable: true,
        },
        anchor: 1,
        after() {
          if (window.innerWidth <= 768) {
            return;
          }
          // const outlineElement = document.getElementById('outline');
          // Vditor.outlineRender(
          //   document.getElementById('vditor-preview'),
          //   outlineElement
          // );
          // if (outlineElement.innerText.trim() !== '') {
          //   outlineElement.style.display = 'block';
          //   initOutline();
          // }
        },
      });
    }, []);

    return (
      <div id="preview">
        <div
          id="vditor-preview"
          className={`vditor ${className} `}
          style={style}
        />
        <div
          id="outline"
          className="fixed w-[186px] top-[117px] right-[20px] bottom-[50px] overflow-auto"
        />
      </div>
    );
  }
);

export default MarkdownPreview;
