import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Avatar, Button, Dropdown, Input, message, Modal, Space } from 'antd';
import { Icon } from '@/icons';
import { Project } from '@/types';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import Authorized from '@/components/authorized';
import Table from '@/components/table';
import ToggleButton from '@/components/toggle-button';
import { TeamContext } from '../contexts';
import {
  selectAppLoading,
  selectCurrentProject,
  selectProjects,
} from '../slice/selectors';
import {
  changeCurrentProject,
  createProject,
  favoriteProject,
  modifyProject,
  queryProjects,
  removeProject,
  switchPrimaryProject,
  unfavoriteProject,
} from '../slice/thunks';
import ProjectForm from './form';

const Projects = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appLoading = useSelector(selectAppLoading);
  const projects = useSelector(selectProjects);
  const currentProject = useSelector(selectCurrentProject);
  const [selectedRow, setSelectedRow] = useState<Project>(null);
  const { id } = useParams();
  const [params, setParams] = useState({
    page: 1,
    size: 10,
    title: '',
  });

  const [open, setOpen] = useState(false);

  const { team_owner } = useContext(TeamContext);

  useEffect(() => {
    fetchProjects(params);
  }, [params, id]);

  const fetchProjects = useCallback(
    (values) => {
      dispatch(queryProjects({ ...values }));
    },
    [dispatch, params, id]
  );

  const handleSubmit = useCallback(
    (values) => {
      if (values.id) {
        dispatch(
          modifyProject({
            ...values,
            async resolve(value) {
              message.success('修改成功');
            },
            reject() {
              message.error('修改失败');
            },
          })
        );
      } else {
        dispatch(
          createProject({
            ...values,
            async resolve(value) {
              message.success('创建成功');
            },
            reject() {
              message.error('创建失败');
            },
          })
        );
      }
    },
    [dispatch, currentProject]
  );

  const handleEdit = useCallback(
    (item) => {
      dispatch(changeCurrentProject(item));
      setOpen(true);
    },
    [dispatch]
  );

  const handleClose = useCallback(() => {
    dispatch(changeCurrentProject(null));
    setOpen(false);
  }, [dispatch]);

  const handleMenuClick = async ({ key }, item) => {
    switch (key) {
      case 'edit':
        await handleEdit(item);
        break;
      case 'delete':
        await Modal.confirm({
          icon: <ExclamationOutlined />,
          content: '是否删除该项目?',
          async onOk() {
            await dispatch(removeProject({ ...item }));
          },
          onCancel() {},
        });
        break;
    }
  };

  const handleFavoriteClick = useCallback(
    (item) => {
      if (item.favorite) {
        dispatch(
          unfavoriteProject({
            ...item,
            resolve() {
              message.success('已取消收藏');
            },
          })
        );
      } else {
        dispatch(
          favoriteProject({
            ...item,
            resolve() {
              message.success('已收藏');
            },
          })
        );
      }
    },
    [dispatch]
  );

  const handleSwtichProject = useCallback(
    (item) => {
      dispatch(switchPrimaryProject({ ...item }));
    },
    [dispatch]
  );

  const columns = [
    {
      dataIndex: 'title',
      width: 30,
      render: (text) => (
        <Avatar
          shape="square"
          style={{
            backgroundColor: '#4F46E5',
            verticalAlign: 'middle',
          }}
          size={30}
        >
          {text?.[0]}
        </Avatar>
      ),
    },
    {
      dataIndex: 'title',
      render: (text) => <span className=" font-bold">{text}</span>,
    },
    {
      dataIndex: 'action',
      align: 'right',
      render: (_, item) => (
        <Space>
          <ToggleButton
            tipProps={{ title: '设置成功后,每次进入默认打开项目' }}
            buttonProps={{
              type: 'link',
              size: 'small',
              onClick: () => handleSwtichProject(item),
            }}
            hoverable={selectedRow?.id === item.id || item.primary}
          >
            <Icon
              type="icon-active"
              style={{ fontSize: '20px', width: '20px', height: '20px' }}
            />
          </ToggleButton>
          <ToggleButton
            tipProps={{ title: item.favorite ? '取消收藏' : '收藏项目' }}
            buttonProps={{
              type: 'link',
              size: 'small',
              onClick: () => handleFavoriteClick(item),
            }}
          >
            <Icon
              type={`${item.favorite ? 'icon-star-full' : 'icon-star'}`}
              style={{ fontSize: '20px', width: '20px', height: '20px' }}
            />
          </ToggleButton>
          <ToggleButton
            tipProps={{ title: '打开项目' }}
            buttonProps={{
              type: 'link',
              size: 'small',
              onClick: () => {
                localStorage.setItem('params',item.team_id)
                localStorage.setItem('params-1',item.id)
                navigate(`/teams/${item.team_id}/projects/${item.id}/events`);
              },
            }}
          >
            <Icon
              type="icon-open"
              style={{ fontSize: '20px', width: '20px', height: '20px' }}
            />
          </ToggleButton>
          <Authorized authority={team_owner} denied={<></>}>
            <Dropdown
              placement="bottom"
              menu={{
                items: [
                  { label: '编辑', key: 'edit', icon: <EditOutlined /> },
                  { type: 'divider' },
                  {
                    label: '删除',
                    key: 'delete',
                    icon: <DeleteOutlined />,
                    danger: true,
                  },
                ],
                onClick: (e) => handleMenuClick(e, item),
              }}
            >
              <Button type="link" size="small">
                <MoreOutlined />
              </Button>
            </Dropdown>
          </Authorized>
        </Space>
      ),
    },
  ];

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <Input
          placeholder="搜索项目"
          prefix={<SearchOutlined />}
          style={{ width: 300 }}
          onChange={(e) => {
            setParams({
              ...params,
              title: e.target.value,
            });
          }}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={(e) => setOpen(true)}
        >
          新建项目
        </Button>
      </div>

      <Table
        loading={appLoading}
        columns={columns}
        showHeader={false}
        rowKey="id"
        onRow={(record) => {
          return {
            onMouseEnter: (e) => {
              setSelectedRow(record);
            },
            onMouseLeave: () => {
              setSelectedRow(null);
            },
          };
        }}
        dataSource={projects?.data || []}
        pagination={{
          pageSize: params?.size,
          onChange(page, pageSize) {
            setParams({ ...params, page, size: pageSize });
          },
          total: projects?.count,
        }}
      />

      {open && (
        <ProjectForm
          open={open}
          currentItem={currentProject}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default Projects;
