import { Navigate, useLocation, useMatch } from 'react-router-dom';
import { getRoute, getRouteList } from '@/utils';
import { useAuthorize } from '@/hooks/useAuthorize';
import { getRoutes } from './routes';

// * Static Router(静态路由，必须配置首页地址，否则不能进首页获取菜单、按钮权限等数据)，获取数据的时候会loading，所有配置首页地址也没问题
const routes = getRoutes();

const routerList = [...getRouteList(routes), '/welcome', '/403', '/404'];

const userList = [
  '/users/login',
  '/users/register',
  '/users/forget-password',
  '/users/edit-password',
];

const AuthorizeRoute = (props: { children: JSX.Element }) => {
  const { pathname } = useLocation();
  const { currentUser } = useAuthorize();
  console.log(currentUser?.teams)
  const primaryTeam = currentUser?.teams?.find((team) => team.primary);

  const redirect = localStorage.getItem('redirect');

  const route = getRoute(pathname, routes);

  // * 判断当前路由是否需要访问权限(不需要权限直接放行)
  if (!route?.meta?.auth) return props.children;

  // * 判断是否登录
  if (!currentUser) return <Navigate to="/users/login" replace />;

  console.log(pathname,redirect,primaryTeam)
  if (['/', '/welcome'].includes(pathname)) {
    // * 判断是否跳转
    if (redirect) {
      localStorage.removeItem('redirect');
      return <Navigate to={redirect} replace />;
    }

    if (primaryTeam) {
      return <Navigate to={`/teams/${primaryTeam.id}`} replace />;
    }
  }

  // * 如果访问的地址没有在路由表中重定向到403页面
  if (routerList.indexOf(pathname) == -1) return <Navigate to="/403" />;

  // * 当前账号有权限返回 Router，正常访问页面
  return props.children;
};

export default AuthorizeRoute;
