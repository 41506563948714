import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button, Form, Input, message } from 'antd';
import Mark from '@/assets/images/mark.svg';
import { signUp } from '@/slice/thunks';
import SharedLink from './shard-link';

const Register = ({ appLoading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignUp = useCallback(
    (values) => {
      dispatch(
        signUp({
          user: values,
          resolve: (value) => {
            message.success('注册成功');
            navigate('/');
          },
          reject: (error) => {
            message.error({
              key: '500',
              content: JSON.stringify(error?.data?.errors),
            });
          },
        })
      );
    },
    [dispatch]
  );

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto h-10" src={Mark} alt="国立软件" />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          注册
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <Form layout="vertical" onFinish={handleSignUp}>
          <Form.Item label="名字" name="name" rules={[{ required: false }]}>
            <Input placeholder="请输入名字" />
          </Form.Item>
          <Form.Item
            label="邮箱"
            name="email"
            rules={[{ required: true, type: 'email' }]}
          >
            <Input placeholder="请输入邮箱" />
          </Form.Item>
          <Form.Item
            label="用户名"
            name="username"
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入用户名" />
          </Form.Item>
          <Form.Item label="密码" name="password" rules={[{ required: true }]}>
            <Input.Password placeholder="请输入密码" />
          </Form.Item>
          <Form.Item
            label="确认密码"
            name="password_confirmation"
            rules={[{ required: true }]}
          >
            <Input.Password placeholder="请输入密码" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              className="w-full"
              htmlType="submit"
              loading={appLoading}
            >
              提交
            </Button>
          </Form.Item>
        </Form>
        <SharedLink />
      </div>
    </div>
  );
};

export default Register;
