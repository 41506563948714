import { useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import Loading from '@/components/loading';
import { useAuthorize } from '@/hooks/useAuthorize';

const SsoCallback = () => {
  const {redirect} = useParams();
  const navigate = useNavigate();
  const { current } = useAuthorize();

  useLayoutEffect(() => {
    current(() => {
      navigate(redirect ? redirect : '/welcome');
    });
  }, [redirect]);

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <Loading>
        <h4>请稍后，页面自动跳转....</h4>
      </Loading>
    </div>
  );
};

export default SsoCallback;
