import dayjs from '@/utils/dayjs';

export const eachWeekOfInterval = (interval) => {
  let startDate = dayjs(interval.start);
  let endDate = dayjs(interval.end);


  if (!(startDate <= endDate)) {
    throw new RangeError('Invalid interval');
  }

  const startDateWeek = dayjs(startDate).startOf('week');
  const endDateWeek = dayjs(endDate).endOf('week');

  endDate = endDateWeek;

  const weeks = [];

  let currentWeek = startDateWeek;

  while (currentWeek <= endDate) {
    weeks.push(currentWeek);
    currentWeek = dayjs(currentWeek).add(1, 'week');
  }


  return weeks;
};



export const eachMinuteOfInterval = (interval) => {
  let startDate = dayjs(interval.start).startOf("minute");
  let endDate = dayjs(interval.end);


  if (!(startDate <= endDate)) {
    throw new RangeError('Invalid interval');
  }
  const dates = [];

  let currentDate = startDate;

  while (currentDate <= endDate) {
    dates.push(currentDate);
    currentDate = dayjs(currentDate).add(60, 'minute');
  }


  return dates;
};


export const isWithinInterval = (date,interval) =>{
    const startTime = interval.start
    const endTime = interval.end

    if(!(startTime <= endTime)){
      throw new RangeError('Invalid interval');
    }

    return date >= startTime &&  date <= endTime
}



export const differenceInCalendarWeeks = (left,right) =>{

  const startWeekLeft = dayjs(left).startOf("week")

  const startWeekRight = dayjs(right).endOf("week")

  return dayjs.duration(startWeekLeft.diff(startWeekRight)).as("week")
}



export const differenceInMinutes = (left,right) =>{

  const startMinuteLeft = dayjs(left).startOf("minutes")

  const startMinuteRight = dayjs(right).startOf("minutes")

  return dayjs.duration(startMinuteLeft.diff(startMinuteRight)).as("minutes")
}

export const differenceInDays = (left,right) =>{

  const startDayLeft = dayjs(left).startOf("day")

  const startDayRight = dayjs(right).endOf("day")

  return dayjs.duration(startDayRight.diff(startDayLeft)).as("day")
}


export const closestTo = (date,dates) =>{
  return dayjs.max(date,...dates);
}
