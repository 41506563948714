import injectReducerEnhancer from '@/utils/@reduxjs/injectReducer/enhancer';
import rejectedErrorHandlerMiddleware from '@/utils/@reduxjs/rejectedErrorHandlerMiddleware';
import { configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createReducer } from './reducers';


const presistConfig = {
  key: 'note-app',
  version: 1,
  storage,
};

export function configureAppStore() {
  const enhancers = [injectReducerEnhancer(createReducer)];

  const reducer = createReducer();

  //redux 缓存
  const persistedReducer = persistReducer(presistConfig, reducer);

  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          //忽略了 Redux Persist 调度的所有操作类型。这样做是为了在浏览器控制台读取a non-serializable value was detected in the state时不会出现错误。
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
        // serializableCheck: false,
        // immutableCheck: false,
      }).prepend(rejectedErrorHandlerMiddleware.middleware),
    devTools:
      /* istanbul ignore next line */
      import.meta.env.MODE !== 'production',
    enhancers,
  });
  return store;
}

export const store = configureAppStore();

export const persistor = persistStore(store);
