import { Fragment, useCallback, useContext, useEffect } from 'react';
import { Spin, Typography } from 'antd';
import Loading from '@/components/loading';
import dayjs from '@/utils/dayjs';
import Cell from '../Cell';
import { SchedulerContext } from '../context';
import Item from '../events/Item';
import TodayItem from '../events/TodayItem';
import Grid from '../Grid';
import { dayWithMinute, filterHeaderEvents, filterTodayEvents } from '../utils';
import { WithResources } from '../WithResources';

const Day = () => {
  const {
    hours: conditionHours,
    currentDate,
    events,
    fetchEvents,
    handleSetState,
    loading,
    currentUser,
  } = useContext(SchedulerContext);
  const [startDate, endDate, hours] = dayWithMinute({
    currentDate,
    hours: conditionHours,
  });

  const ITEM_HEIGHT = 35;

  const CELL_HEIGHT = 65;
  const MINUTE_HEIGHT = Math.ceil(CELL_HEIGHT) / 60;

  const handleFetchEvents = useCallback(async () => {
    const start = currentDate?.startOf('day').format('YYYY-MM-DD HH:mm:ss');
    const end = currentDate?.endOf('day').format('YYYY-MM-DD HH:mm:ss');
    await fetchEvents?.({ user_id: currentUser, start, end });
  }, [currentUser, currentDate, fetchEvents]);

  useEffect(() => {
    handleFetchEvents();
  }, [currentUser, currentDate, fetchEvents]);

  const renderHeaderEvents = (allEvents) => {
    const todayEvents = filterHeaderEvents(allEvents, currentDate);

    return (
      <div
        className="block_col"
        style={{ height: ITEM_HEIGHT * todayEvents.length }}
      >
        {todayEvents.map((event, i) => {
          const hasPrev = dayjs(event.start).isBefore(
            dayjs(currentDate).startOf('day')
          );
          const hasNext = dayjs(event.end).isAfter(
            dayjs(currentDate).endOf('day')
          );

          return (
            <div
              key={i}
              className="multiple-events last:pb-2"
              style={{
                top: i * ITEM_HEIGHT,
                width: '100%',
                overflowX: 'hidden',
                borderRadius: 5,
              }}
            >
              <Item
                key={i}
                event={event}
                header
                hasPrev={hasPrev}
                hasNext={hasNext}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderTable = () => {
    const todayEvents = filterHeaderEvents(events, currentDate);
    return (
      <>
        <Grid sticky>
          <span className="cell"></span>
          <span
            className="cell cell__header"
            style={{ height: ITEM_HEIGHT * todayEvents.length + 45 }}
          >
            <div
              style={{
                color: dayjs(currentDate).isToday() ? '#7d72f2' : 'black',
                fontWeight: dayjs(currentDate).isToday() ? 'bolder' : '',
              }}
            >
              {currentDate.format('ddd')}
              <br />
              {currentDate.format('MM-DD')}
            </div>
            {renderHeaderEvents(events)}
          </span>
        </Grid>
        <Grid>
          {hours.map((h, i) => {
            const end = h.add(60, 'minute');
            return (
              <Fragment key={i}>
                <span
                  className="cell cell__header cell__time"
                  style={{ height: 66 }}
                >
                  <Typography>{h.format('HH:mm A')}</Typography>
                </span>
                <span className="cell">
                  {i === 0 && (
                    <TodayItem
                      key={i}
                      events={filterTodayEvents(events, currentDate)}
                      today={startDate}
                      startHour={hours[0]}
                      minuteHeight={MINUTE_HEIGHT}
                    />
                  )}
                  <Cell
                    style={{ height: CELL_HEIGHT }}
                    start={h}
                    end={end}
                    day={currentDate}
                    id={`day-${i}`}
                  />
                </span>
              </Fragment>
            );
          })}
        </Grid>
      </>
    );
  };

  return (
    <WithResources>
      <>{renderTable()}</>
    </WithResources>
  );
};

export default Day;
