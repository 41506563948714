import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import pluralGetSet from 'dayjs/plugin/pluralGetSet'
import  weekOfYear from 'dayjs/plugin/weekOfYear'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import updateLocale from 'dayjs/plugin/updateLocale';
import isToday from 'dayjs/plugin/isToday'
import minMax from 'dayjs/plugin/minMax'
import 'dayjs/locale/zh-cn';
dayjs.extend(weekOfYear)
dayjs.extend(duration);
dayjs.extend(pluralGetSet);
dayjs.extend(quarterOfYear);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(updateLocale);
dayjs.extend(isToday);
dayjs.extend(minMax);
dayjs.locale('zh-cn');

export default dayjs;
