import { AppInfo, Invite, PageData, RootState, Tag, Team, User } from '@/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { request } from '@/utils/request';
import { appActions } from '.';
import {
  ForgotPaswordParams,
  JoinTeamsParams,
  QueryCurrentParams,
  QueryInviteParams,
  QueryTagParams,
  QueryTeamsParams,
  SignInParams,
  SignOutParams,
  SignUpParams,
} from './types';

export const getAppInfo = createAsyncThunk<AppInfo>('app/info', async () => {
  const { data } = await request<AppInfo>('version');
  return data;
});

export const getCurrentUser = createAsyncThunk<
  User,
  QueryCurrentParams,
  { state: RootState }
>('app/currentUser', async ({ reject, resolve }, { dispatch }) => {
  const { data } = await request<User>('/api/v1/users/current', null, {
    onFulfilled: resolve,
    onRejected(error) {
      reject(error);
    },
  });

  appActions.updateCurrentUser(data);
  return data;
});

export const getProviders = createAsyncThunk<string[]>(
  'app/providers',
  async () => {
    const { data } = await request<string[]>('/api/v1/users/providers');
    return data;
  }
);

export const signIn = createAsyncThunk<User, SignInParams>(
  'app/signIn',
  async ({ user, resolve }) => {
    const { data } = await request<User>('/users/sign_in', {
      method: 'POST',
      data: {user},
    });
    appActions.updateCurrentUser(data);
    resolve(data);
    return data;
  }
);

export const signOut = createAsyncThunk<User, SignOutParams>(
  'app/signOut',
  async ({ resolve, reject }) => {
    const { data } = await request<User>(
      '/users/sign_out',
      { method: 'DELETE' },
      {
        onRejected(error) {
          reject(error);
        },
      }
    );
    appActions.updateCurrentUser(null);
    resolve(data);
    return data;
  }
);

export const signUp = createAsyncThunk<User, SignUpParams>(
  'app/signUp',
  async ({ user, resolve, reject }) => {
    const { data } = await request<User>(
      '/users',
      { method: 'POST', data: { user } },
      {
        onRejected(error) {
          reject(error);
        },
      }
    );
    appActions.updateCurrentUser(null);
    resolve(data);
    return data;
  }
);

export const forgotPasword = createAsyncThunk<User, ForgotPaswordParams>(
  'app/forgotPasword',
  async ({ user, resolve, reject }) => {
    const { data } = await request<User>(
      '/users/password',
      { method: 'POST', data: { user } },
      {
        onRejected(error) {
          reject(error);
        },
      }
    );
    appActions.updateCurrentUser(null);
    resolve(data);
    return data;
  }
);

export const resetPassword = createAsyncThunk<User, SignUpParams>(
  'app/resetPassword',
  async ({ user, resolve, reject }) => {
    const { data } = await request<User>(
      '/users/password',
      { method: 'PUT', data: { user } },
      {
        onRejected(error) {
          reject(error);
        },
      }
    );
    appActions.updateCurrentUser(null);
    resolve(data);
    return data;
  }
);

export const queryTeams = createAsyncThunk<Team[], QueryTeamsParams>(
  'app/queryTeams',
  async (params) => {
    const { data = [] } = await request<Team[]>('/api/v1/teams', {
      method: 'GET',
      params,
    });
    return data;
  }
);

export const createTeam = createAsyncThunk<Team, QueryTeamsParams>(
  'app/createTeam',
  async ({ title, reject, resolve }) => {
    const { data } = await request<Team>(
      '/api/v1/teams',
      { method: 'POST', data: { title } },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);

export const joinTeam = createAsyncThunk<Team, JoinTeamsParams>(
  'app/joinTeam',
  async ({ id, resolve, reject }) => {
    const { data } = await request<Team>(
      `/api/v1/teams/${id}/join`,
      { method: 'POST' },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);

export const showInvite = createAsyncThunk<Invite, QueryInviteParams>(
  'app/showInvite',
  async ({ token, resolve, reject }) => {
    const { data } = await request<Invite>(
      `/api/v1/invites/${token}`,
      { method: 'GET' },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);

export const joinInvite = createAsyncThunk<Invite, QueryInviteParams>(
  'app/joinInvite',
  async ({ token, resolve, reject }) => {
    const { data } = await request<Invite>(
      `/api/v1/invites/${token}`,
      { method: 'PUT' },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);

export const setPageTitle = createAsyncThunk<null, string>(
  'app/setPageTitle',
  async (title) => {
    appActions.savePageTitle({ title });
  }
);
