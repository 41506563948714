import { message } from 'antd';
import { ApiResponse } from '@/types';
import axios, { AxiosRequestConfig } from 'axios';
import { InternalAxiosRequestConfig } from 'axios';
import { useLocalStorage } from '@/hooks/useLocalStorage';

const CODE_MESSAGE = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '参数错误',
  401: '登陆失败，请重试',
  404: '请求资源不存在',
  408: '请求超时',
  500: '服务器内部错误',
  501: '服务未实现',
  502: '服务无法访问',
  503: '服务不可用',
  504: '服务超时',
  505: 'http版本不支持',
};

const BASE_URL = import.meta.env.VITE_BASE_URL;

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 60 * 1000, //设置超时
  headers: {
    'Content-Type': 'application/json;charset=UTF-8;',
    'Access-Control-Allow-Origin': '*',
  },

  xsrfCookieName: '_eagle',
  withCredentials: true,
  xsrfHeaderName: 'X-CSRF-Token',
  validateStatus(status) {
    return status < 400;
  },
});

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  config.headers.set('x-item', localStorage.getItem('params'));
  config.headers.set('x-item-1', localStorage.getItem('params-1'));
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    console.error(err?.response);
    message.destroy();
    return Promise.reject(err?.response);
  }
);

export function request<T>(
  url: string,
  opts: AxiosRequestConfig = { method: 'GET' },
  extra?: { onFulfilled?: (value) => T; onRejected?: (error) => any }
): Promise<ApiResponse<T>> {
  return new Promise((resolve, reject) => {
    instance
      .request({ ...opts, url })
      .then(
        (res) => {
          resolve(res.data);
          extra?.onFulfilled?.(res.data);
        },
        (err) => {
          reject(err);
          standardErrorMessageTransformer(err);
          extra?.onRejected?.(err);
        }
      )
      .catch((error) => {
        reject(error);
        standardErrorMessageTransformer(error);
        extra?.onRejected?.(error);
      });
  });
}

function standardErrorMessageTransformer(error) {
  const { status, data } = error;

  if (status === 401) {
    message.error({ key: '401', content: CODE_MESSAGE[401] });
    localStorage.removeItem('current-user');
    return true;
  }

  if (status >= 404) {
    message.error({ key: '404', content: data.error.join('\n') });
    return true;
  }
  return error;
}
