import { memo } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { ConfigProvider, Watermark } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import { StyleProvider } from '@ant-design/cssinjs';
import { PersistGate } from 'redux-persist/integration/react';
import { AuthorizeProvider } from './hooks/useAuthorize';
import AppRoute from './routes';
import { persistor, store } from './store';

export const App = memo(() => {
  const IS_DEV = import.meta.env.MODE !== 'production';
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HelmetProvider>
          <ConfigProvider
            prefixCls="custom"
            locale={zhCN}
            theme={{
              token: {
                colorPrimary: '#4F46E5',
                colorLink: '#4F46E5',
              },
            }}
          >
            <StyleProvider hashPriority="high">
              <Watermark content={IS_DEV ? '国立软件' : ''}>
                <HashRouter>
                  <AuthorizeProvider>
                    <AppRoute />
                  </AuthorizeProvider>
                </HashRouter>
              </Watermark>
            </StyleProvider>
          </ConfigProvider>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  );
});
