import { RootState } from "@/types";
import { createSelector } from "reselect";
import { initialState } from ".";




const selectRoot = (state: RootState) => state.project || initialState;


export const selectAppLoading = createSelector([selectRoot],projectState=>projectState.appLoading)

export const selectArticles = createSelector([selectRoot],projectState=>projectState.articles)

export const selectCurrentArticle = createSelector([selectRoot],projectState=>projectState.currentArticle)

export const selectTags = createSelector([selectRoot],appState=>appState.tags)

export const selectUsers = createSelector([selectRoot],appState=>appState.users)

export const selectEvents = createSelector([selectRoot],appState=>appState.events)

export const selectProjectLevels = createSelector([selectRoot],appState=>appState.projectLevels)

export const selectEventSettings = createSelector([selectRoot],appState=>appState.eventSettings)
