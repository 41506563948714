import './index.scss';
import { LayoutMode } from './types';

const Grid = ({
  children,
  sticky = false,
  days = 1,
  week = false,
  ...props
}) => {
  return (
    <div
      className={`basic-grid ${sticky ? 'sticky' : 'relative'} body-${days}${
        week ? '-week' : ''
      }`}
      {...props}
    >
      {children}
    </div>
  );
};

export default Grid;
