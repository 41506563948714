import { memo, useCallback, useContext } from 'react';
import { Button, DatePicker, Radio } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import dayjs from '@/utils/dayjs';
import { SchedulerContext } from './context';

const Toolbar = memo(() => {
  const { currentDate, handleSetState, view } = useContext(SchedulerContext);

  const options = [
    {
      label: '今天',
      value: 'today',
    },
    {
      label: '日',
      value: 'day',
    },
    {
      label: '周',
      value: 'week',
    },
    {
      label: '月',
      value: 'month',
    },
  ];

  const formtMap = {
    today: "YYYY年MM月DD日",
    day: "YYYY年MM月DD日",
    week: "YYYY年MM月",
    month: "YYYY年MM月"
  }

  const handleChangeDate = useCallback(
    (prev) => {
      let currentView = view === 'today' ? 'day' : view;
      let date;
      if (prev) {
        date = currentDate?.subtract(1, currentView);
      } else {
        date = currentDate?.add(1, currentView);
      }
      handleSetState?.({ currentDate: date });
    },
    [currentDate, handleSetState, view]
  );

  return (
    <div className="flex flex-1 bg-white dark:bg-slate-800 dark:text-white">
      <div className="flex-1">
        <Button
          icon={<LeftOutlined />}
          type="link"
          onClick={(e) => handleChangeDate(true)}
        />
        <DatePicker
          bordered={false}
          value={currentDate}
          format={formtMap[view]}
          picker={view}
          allowClear={false}
          className="dark:bg-slate-800 dark:text-white w-[160px] font-bold"
          onChange={(date) => handleSetState?.({ currentDate: date })}
        />
        <Button
          icon={<RightOutlined />}
          type="link"
          onClick={(e) => handleChangeDate(false)}
        />
      </div>
      <Radio.Group
        options={options}
        value={view}
        optionType="button"
        buttonStyle="solid"
        className="dark:bg-slate-800 dark:text-white "
        onChange={(e) => {
          let obj = {};
          if (e.target.value === 'today') {
            obj.currentDate = dayjs();
          }
          handleSetState?.({ ...obj, view: e.target.value });
        }}
      />
    </div>
  );
});

export default Toolbar;
