import { useCallback } from 'react';
import { Button, ColorPicker, Form, Input, Modal, Space } from 'antd';

const LevelForm = ({ currentItem, onCancel, onOk, ...props }) => {
  const title = currentItem ? '修改优先级' : '添加优先级';
  const [form] = Form.useForm();
  const handleSubmit = useCallback(async () => {
    await form.validateFields();
    await onOk?.({ ...currentItem, ...form.getFieldsValue() });
  }, [form, onOk]);

  const handleCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);
  return (
    <Modal
      {...props}
      title={title}
      onOk={handleSubmit}
      onCancel={handleCancel}
      destroyOnClose
    >
      <Form
        form={form}
        initialValues={{ ...currentItem }}
        onFinish={handleSubmit}
        preserve={false}
      >
        <Form.Item
          label="名称"
          name="label"
          rules={[{ required: true, message: '请输入标签名称' }]}
        >
          <Input placeholder="请输入名称" />
        </Form.Item>

        <Form.Item label="描述" name="remark">
          <Input.TextArea rows={3} placeholder="请输入描述" />
        </Form.Item>
        <Form.Item
          label="颜色"
          name="color"
          rules={[{ required: true, message: '请选择标签颜色' }]}
          getValueFromEvent={(e) => {
            return e?.metaColor?.originalInput;
          }}
        >
          <ColorPicker
            format="rgb"
            presets={[
              {
                label: '默认',
                colors: [
                  '#F5222D',
                  '#FA8C16',
                  '#FADB14',
                  '#8BBB11',
                  '#52C41A',
                  '#13A8A8',
                  '#1677FF',
                  '#2F54EB',
                  '#722ED1',
                  '#EB2F96',
                  '#F5222D4D',
                  '#FA8C164D',
                  '#FADB144D',
                  '#8BBB114D',
                  '#52C41A4D',
                  '#13A8A84D',
                  '#1677FF4D',
                  '#2F54EB4D',
                  '#722ED14D',
                  '#EB2F964D',
                ],
              },
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LevelForm;
