/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from '@reduxjs/toolkit';
import { InjectedReducersType } from '@/utils/types/injector-typings';
import { persistReducer } from 'redux-persist';

import storage from 'redux-persist/es/storage';

const presistConfig = {
  key: 'note-app',
  version: 1,
  storage,
};

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  if (Object.keys(injectedReducers).length === 0) {
    return state => state;
  }

  let newReducers = {}

  Object.keys(injectedReducers).forEach(key=>{
    newReducers[key] = persistReducer(presistConfig, injectedReducers[key]);
  })

  return combineReducers({
    ...newReducers,
  });
}
