import { User } from "@/types";


export type STATE_TYPES = {
    waiting: '',
    checked: ''
}

export interface EventItem{
    id: string | number;
    title: string;
    start: string;
    end: string;
    user_id?: string | number;
    state?: string;

}

export interface SchedulerProps{
    events: EventItem[];
    hours?: number[];
    users?: User[];
}

export enum LayoutMode {
    ROW = 1,
    COLUMN = 2
}

export type WeekDays = 0 | 1 | 2 | 3 | 4 | 5 | 6;


export const defaultWeekDays: WeekDays[] = [0,1,2,3,4,5,6];
