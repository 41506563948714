import React, { memo, useEffect, useState } from 'react';
import { message } from 'antd';
import { User } from '@/types';
import { DirectUpload } from '@rails/activestorage';
import Cookies from 'js-cookie';
import Vditor from 'vditor';
import 'vditor/dist/index.css';
import useDebounce from '@/hooks/useDebounce';
import './index.scss';
import toolbars from './toolbars';

type MarkdownEditorProps = {
  value?: null | string;
  style?: React.CSSProperties;
  className?: string;
  onChange?: (e: any) => void;
  onSave?: (e: any) => void;
  users?: User[];
  options: any;
};

const MarkdownEditor: React.FC<MarkdownEditorProps> = memo(
  ({ value, style, className, onChange, onSave, users, options }) => {
    const [vd, setVd] = useState<Vditor>();
    console.log('MarkdownEditor');
    useEffect(() => {
      if (!vd) {
        const vditor = new Vditor('vditor', {
          width: '100%',
          height: '100%',
          toolbar: [...toolbars],
          preview: {
            markdown: {
              toc: true,
              mark: true,
              footnotes: true,
              autoSpace: true,
            },
            math: {
              engine: 'KaTeX',
            },
          },
          toolbarConfig: {
            pin: false,
            hide: false,
          },
          counter: {
            enable: true,
            type: 'text',
          },
          mode: 'ir',
          placeholder: 'Ctrl / ⌘ + S 保存文章',
          typewriterMode: true,
          debugger: import.meta.env.MODE !== 'production',
          upload: {
            multiple: true,
            filename(name) {
              return name
                .replace(/[^(a-zA-Z0-9\u4e00-\u9fa5\.)]/g, '')
                .replace(/[\?\\/:|<>\*\[\]\(\)\$%\{\}@~]/g, '')
                .replace('/\\s/g', '');
            },
            handler(files) {
              const uploading = '文件上传中，请稍等！';
              vditor.insertValue(uploading);
              vditor.disabled();
              Array.from(files).forEach((file) => {
                const upload = new DirectUpload(
                  file,
                  '/api/v1/rails/active_storage/direct_uploads',
                  {
                    directUploadWillCreateBlobWithXHR: (xhr) => {
                      xhr.setRequestHeader(
                        'X-Csrf-Token',
                        Cookies.get('_eagle')
                      );
                    },
                  }
                );

                upload.create((error, blob) => {
                  if (error) {
                    message.success('上传失败');
                  } else {
                    let result = '';
                    if (blob.content_type.indexOf('image') > -1) {
                      result =
                        vditor.getCurrentMode() === 'ir'
                          ? `![${blob?.filename}](${blob?.service_url})`
                          : `<img src="${blob?.service_url}"  alt="${blob?.filename}"/>`;
                    } else if (blob.content_type.indexOf('video/webm') > -1) {
                      result = `<audio controls="controls" preload src="${blob?.service_url}"  alt="${blob?.filename}"/>`;
                    } else if (blob.content_type.indexOf('video') > -1) {
                      result = `<video style="width: 100%;height: 400px" controls="controls" src="${blob?.service_url}"  alt="${blob?.filename}"/>`;
                    } else {
                      result =
                        vditor.getCurrentMode() === 'ir'
                          ? `[${blob?.filename}](${blob?.service_url})`
                          : `<a href="${blob?.service_url}" title="${blob?.filename}">${blob?.filename}</a>`;
                    }

                    let content = vditor.getValue();
                    content = content.replace(uploading, result);
                    vditor.setValue(content);
                    vditor.insertValue('', true);
                    vditor.focus();
                    vditor.enable();
                    message.success('上传成功');
                  }
                });
              });
              return;
            },
          },
          tab: '\t',
          after: () => {
            vditor.setValue(value ? value : '');
            setVd(vditor);
          },
          focus: (e) => {},
          blur: (e) => {},
          input(value) {
            onChange?.(value);
          },
          hint: {
            extend: [
              {
                key: '@',
                hint(value) {
                  return users?.map(({ username, avatar_url, id }) => ({
                    value: `@[${username}](/#/team/users/profile/${id})`,
                    html: `<img src="${avatar_url}"/> ${username}`,
                  }));
                },
              },
            ],
          },
          cache: {
            enable: true,
            id: 'vditor-storage',
          },
          ...options,
        });
      }
    }, []);

    useEffect(() => {
      document?.addEventListener(
        'keydown',
        (e) => {
          if (e.key === 's' && (e.ctrlKey || e.metaKey)) {
            e.preventDefault();
            handleSave(e);
            return false;
          }
          return true;
        },
        true
      );
    }, [value, vd]);

    const handleSave = useDebounce(
      async (event) => {
        vd?.disabled();
        await onSave?.({
          content: vd?.getValue(),
          content_html: vd?.getHTML(),
        });
        vd.clearCache();
        vd?.focus();
        vd?.enable();
      },
      200,
      [vd]
    );

    return <div id="vditor" className={`vditor ${className}`} style={style} />;
  }
);

export default MarkdownEditor;
