import { useEffect, useMemo, useState } from 'react';
import { Spin } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { differenceInMinutes } from './utils/date-fns';
import { SchedulerContext } from './context';
import Layout from './Layout';
import Toolbar from './Toolbar';
import { LayoutMode } from './types';
import Day from './views/Day';
import Editor from './views/Editor';
import Empty from './views/Empty';
import Month from './views/Month';
import Week from './views/Week';

dayjs.extend(duration);
dayjs.locale('zh-cn');

const Scheduler = (props) => {
  const [initialState, setInitialState] = useState({
    view: 'week',
    currentDate: dayjs(),
    loading: false,
    hours: [9, 18],
    visible: false,
    currentItem: null,
    draggable: true,
    multiple: false,
    ...props,
  });

  // useEffect(() => {
  //   console.log('useEffect 1', initialState);
  //   props?.onChange?.(initialState);
  //   console.log('useEffect 1', initialState);
  // }, [initialState]);

  useEffect(() => {
    const { currentUser, ...otherProps } = props;
    if (initialState?.currentUser !== currentUser) {
      setInitialState({ ...initialState, ...otherProps });
    } else {
      setInitialState({ ...initialState, ...props });
    }
  }, [props]);

  const handleClose = () => {
    setInitialState({ ...initialState, visible: false, currentItem: null });
  };

  const handleGotoDay = (date) => {
    handleSetState({ currentDate: date, view: 'day' });
  };

  const handleSetState = (value) => {
    setInitialState((prev) => ({ ...prev, ...value }));
  };

  const onDrop = async (
    eventId: string,
    startTime,
    resKey?: string,
    resVal?: string | number
  ) => {
    const droppedEvent = initialState.events.find((e) => e.id === eventId);

    const diff = differenceInMinutes(droppedEvent.end, droppedEvent.start);

    const updatedEvent = {
      ...droppedEvent,
      start: startTime,
      end: dayjs(startTime).add(diff, 'minute').format('YYYY-MM-DD HH:mm:ss'),
    };

    await props?.createEvents(updatedEvent);

    setInitialState({
      ...initialState,
      events: initialState.events.map((event) =>
        event.id === eventId ? updatedEvent : event
      ),
    });
  };

  const views = useMemo(() => {
    switch (initialState?.view) {
      case 'month':
        return <Month />;
      case 'week':
        return <Week />;
      case 'today':
      case 'day':
        return <Day />;
      default:
        return <Empty />;
    }
  }, [initialState?.view]);

  return (
    <SchedulerContext.Provider
      value={{
        ...initialState,
        handleSetState,
        onDrop,
        handleGotoDay,
        handleClose,
      }}
      sx={{
        overflowX: initialState.multiple ? 'auto' : undefined,
      }}
    >
      <Spin tip="加载中..." spinning={initialState?.loading} delay={50}>
        <Toolbar />
        <Layout mode={LayoutMode.COLUMN}>{views}</Layout>
        <Editor open={initialState.visible} onCancel={handleClose} start={initialState.start}/>
      </Spin>
    </SchedulerContext.Provider>
  );
};

export default Scheduler;
