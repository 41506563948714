import PageHeader from "@/components/page-header";

const Labels = () =>{

    return <div className=" h-full w-full bg-white dark:bg-slate-800 dark:text-white px-5">
        <PageHeader title={<span className="pr-5">标签</span>} description="通过标签快速筛选文章" />
        </div>
}


export default Labels;
