import { useAuthorize } from '@/hooks/useAuthorize';
import { selectProviders } from '@/slice/selectors';
import { getProviders } from '@/slice/thunks';
import { Button, Divider, Typography } from 'antd';
import Cookies from 'js-cookie';
import { ReactElement, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
const { Text, Link } = Typography;

const SharedLink = memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispacth = useDispatch();
  const providers = useSelector(selectProviders);
  const {current} = useAuthorize()

  useEffect(() => {
    dispacth(getProviders());
  }, [dispacth]);

  const [token, setToken] = useState('');

  useEffect(() => {
    setToken(Cookies.get('_eagle'));
  }, []);

  const renderLink = (link: string, name: string): ReactElement => {
    return (
      <p className="mt-10 text-center text-sm " key={link}>
        <Link onClick={() => navigate(link)} className="select-none link link-hover link-primary">
          {name}
        </Link>
      </p>
    );
  };

  const renderProvider = (provider: string):JSX.Element => {
    return (
      <form
        key={provider}
        className="mt-10 text-center text-sm text-gray-500"
        action={`/users/auth/${provider}`}
        method="post"
      >
        <input type="hidden" value={token} name="authenticity_token"></input>
        <Button type="primary" key={provider} data-turbo = "false" htmlType='submit'>
          使用{provider}登陆
        </Button>
     </form>
    );
  };

  const renderLinks = () => {
    let links: JSX.Element[] = [];
    if (location.pathname !== '/users/login') {
      links.push(renderLink('/users/login', '登陆'));
    }

    if (location.pathname !== '/users/register') {
      links.push(renderLink('/users/register', '注册'));
    }

    if (providers.length) {
      links.push(providers.map(renderProvider));
    }

    return links;
  };

  return <div className="space-y-6"><Divider plain>或者</Divider>{renderLinks()}</div>;
});

export default SharedLink;
