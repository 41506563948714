import { useCallback, useContext, useEffect } from 'react';
import { Avatar, Spin, Typography } from 'antd';
import dayjs from '@/utils/dayjs';
import Cell from '../Cell';
import { SchedulerContext } from '../context';
import MonthItem from '../events/MonthItem';
import Grid from '../Grid';
import { defaultWeekDays } from '../types';
import { monthWithWeek } from '../utils';
import { WithResources } from '../WithResources';

const Month = () => {
  const {
    events,
    hours,
    currentDate,
    handleGotoDay,
    fetchEvents,
    handleSetState,
    loading,
    currentUser,
  } = useContext(SchedulerContext);

  const [startDay, endDay, weeks] = monthWithWeek({ currentDate });

  let dayList = defaultWeekDays.map((d) => weeks[0].add(d, 'day'));

  const CELL_HEIGHT = 120;

  const handleFetchEvents = useCallback(async () => {
    await fetchEvents?.({
      user_id: currentUser,
      start: startDay.format('YYYY-MM-DD 00:00:00'),
      end: endDay.format('YYYY-MM-DD 23:59:59'),
    });
  }, [currentUser, currentDate, fetchEvents]);

  useEffect(() => {
    handleFetchEvents();
  }, [currentUser, currentDate, fetchEvents]);

  const renderCells = () => {
    let rows = [];

    weeks.forEach((week,ii) => {
      const cells = defaultWeekDays.map((d, i) => {
        const today = dayjs(week).add(d, 'day');
        const start = dayjs(today).add(hours[0], 'hour');
        const end = dayjs(today).add(hours[1], 'hour');

        return (
          <span key={i} className="cell">
            <Cell
              start={start}
              end={end}
              day={currentDate}
              id={`month-${ii}-${i}`}
            />
            <Avatar
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: 27,
                height: 27,
                backgroundColor: dayjs(today).isToday()
                  ? '#7d72f2'
                  : 'transparent',
              }}
            >
              <Typography.Link
                style={{
                  color: dayjs(today).isToday()
                    ? 'white'
                    : !dayjs(startDay).isSameOrAfter(today, 'month') ||
                      !dayjs(endDay).isSameOrBefore(today, 'month')
                    ? '#ccc'
                    : 'black',
                }}
                onClick={(e) => handleGotoDay(today)}
              >
                {today.format('DD')}
              </Typography.Link>
            </Avatar>

            <MonthItem
              key={i}
              events={events}
              today={today}
              days={dayList}
              cellHeight={CELL_HEIGHT}
              weeks={weeks}
              onViewMore={handleGotoDay}
            />
          </span>
        );
      });

      rows.push(cells);
    });

    return rows;
  };

  return (
    <WithResources>
      <Grid sticky days={7}>
        {dayList.map((d, i) => {
          return (
            <Typography
              key={i}
              className="cell cell__header cell__header__center"
            >
              {d.format('ddd')}
            </Typography>
          );
        })}
      </Grid>
      <Grid days={7}>{renderCells()}</Grid>
    </WithResources>
  );
};

export default Month;
