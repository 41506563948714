import { Table as AntdTable } from 'antd';

const Table = ({ pagination, ...props }) => {
  return (
    <AntdTable
      className="p-2"
      {...props}
      pagination={{
        position: ['bottomCenter'],
        itemRender: (_, type, originalElement) => {
          if (type === 'prev') {
            return <a className="select-none">上一页</a>;
          }
          if (type === 'next') {
            return <a className="select-none">下一页</a>;
          }

          return originalElement;
        },
        ...pagination,
      }}
    />
  );
};

export default Table;
