import PageHeader from '@/components/page-header';
import { Tabs } from 'antd';

const Collect = () => {
  return (
    <div className="bg-white dark:bg-slate-800 px-5 h-full">
      <PageHeader
        title={<span className="pr-2">我的收藏</span>}
        description="这里收藏了文章、项目、任务..."
      />
      <Tabs items={
        [
            {
                key: 'projects',
                label: '项目',
                children: <div >项目列表</div>
            },
            {
                key: 'articles',
                label: '文章',
                children: <div>文章列表</div>
            },
            {
                key: 'comments',
                label: '评论',
                children: <div>评论列表</div>
            },
        ]
      }/>
    </div>
  );
};

export default Collect;
