import React, { useCallback, useContext } from 'react';
import { Button } from 'antd';
import useDrag from './hooks/useDrag';
import useDrop from './hooks/useDrop';
import dayjs from '@/utils/dayjs';
import { SchedulerContext } from './context';
import Editor from './views/Editor';

interface CellProps {
  children?: React.ReactElement;
  style?: React.CSSProperties;
  start?: dayjs.Dayjs;
  end?: dayjs.Dayjs;
  day?: dayjs.Dayjs;
  id: string;
}

const Cell: React.FC<CellProps> = ({
  children,
  style = {},
  start,
  end,
  day,
  id,
}) => {
  const { handleSetState, visible } = useContext(SchedulerContext);

  const dropProps = useDrop({ start, end, day });

  const handleVisible = useCallback(
    (current) => {
      handleSetState?.({
        visible: !!!visible,
        start,
        end,
        day,
      });
    },
    [start, end, day]
  );

  return (
    <div
      id={id}
      style={{
        height: 120,
        zIndex: 1000,
        width: '100%',
        ...style,
      }}
      className="hover:border-indigo-500 hover:border focus:border-indigo-500 select-none cursor-pointer"
      onClick={handleVisible}
      {...dropProps}
    >
      {children}
    </div>
  );
};

export default Cell;
