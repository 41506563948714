import React from 'react';
import { Button, Tooltip, TooltipProps } from 'antd';
import { ButtonProps } from 'antd/lib/button';

type ToggleButtonProps = {
  children?: React.ReactNode;
  buttonProps?: ButtonProps;
  tipProps?: TooltipProps;
  hoverable?: boolean;
  className?: string;
};

const ToggleButton: React.FC<ToggleButtonProps> = ({
  children,
  buttonProps,
  tipProps,
  hoverable,
  className,
}) => {
  if (hoverable !== undefined && !hoverable) return null;

  return (
    <Tooltip {...tipProps}>
      <Button type="text" {...buttonProps} className={className}>
        {children}
      </Button>
    </Tooltip>
  );
};

export default ToggleButton;
