import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
const useDebounce = (fn, ms, deps) => {
  const { current } = useRef({ fn })


    useEffect(() => {
        current.fn = fn
    }, [current, fn])

    return useCallback(
        function (this: any, ...args: any[]) {
            if (current.timer) {
                clearTimeout(current.timer)
                delete current.timer
            }

            current.timer = setTimeout(() => {
                current.fn.apply(this, args)
            }, debounce);
        },
        // eslint-disable-next-line
        [debounce, current, ...deps],
    )
};
export default useDebounce;
