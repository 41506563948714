import { AppProvider } from '@/hooks/useApp';
import StyleSortLayout from '@/layouts/StyleSortLayout';
import { useProjectSlice } from '@/pages/projects/slice';
import { useTeamSlice } from '@/pages/teams/slice';
import { useAppSlice } from '@/slice';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import AuthorizeRoute from './AuthorizeRoute';
import { getRoutes } from './routes';
import { selectAppLoading } from '@/slice/selectors';

const AppRoute = () => {
  useAppSlice();
  useTeamSlice();
  useProjectSlice();
  const appLoading = useSelector(selectAppLoading)
  console.log(appLoading)
  const routes = useRoutes(getRoutes());
  return (
      <AppProvider>
        <AuthorizeRoute>
          <StyleSortLayout>
          {routes}
          </StyleSortLayout>
        </AuthorizeRoute>
      </AppProvider>
  );
};

export default AppRoute;
