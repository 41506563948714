
import { DragEvent, useContext } from "react";
import { EventItem } from "../types";
import { SchedulerContext } from "../context";

const useDrop = ({start,end,today}) => {
  const { onDrop } = useContext(SchedulerContext)
  return {
    onDragOver: (e: DragEvent<HTMLButtonElement>) => {
      e.currentTarget.className = ' bg-red-200';
      e.preventDefault();
    },
    onDragEnter: (e: DragEvent<HTMLButtonElement>) => {
      e.currentTarget.className = ' bg-red-200';
    },
    onDragLeave: (e: DragEvent<HTMLButtonElement>) => {
      e.currentTarget.className = '';
    },
    onDrop: (e: DragEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.currentTarget.className = '';
      const eventId = e.dataTransfer.getData("text");
      onDrop?.(eventId,start)
    },
  };
};

export default useDrop;
