import { createContext, memo, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { message } from 'antd';
import { getCurrentUser, signIn, signOut } from '@/slice/thunks';
import { Project, Team, User } from '@/slice/types';
import { useLocalStorage } from './useLocalStorage';

export interface AuthorizeContextType {
  currentUser: User;
  // currentTeam: Team;
  // currentProject: Project;
  login: (user: User, cb?: VoidFunction) => void;
  logout: (cb?: VoidFunction) => void;
  current: (cb?: VoidFunction) => void;
}

export const AuthorizeContext = createContext<AuthorizeContextType>(null!);

export const AuthorizeProvider = memo(({ children }) => {
  const dispatch = useDispatch();
  const [currentUser,setCurrentUser] = useLocalStorage('current-user', null);
  const navigate = useNavigate();

  const login = (values, cb) => {
    dispatch(
      signIn({
        user: values,
        resolve: (value) => {
          message.success(
            `${value.name || value.username || value.email},欢迎回来`
          );
          current(cb)
        },
      })
    );
  };


  const current = (cb) => {
    dispatch(
      getCurrentUser({
        reject: () => {
          navigate('/users/login');
        },
        resolve({data}) {
          setCurrentUser(data);
          cb?.(data)
        },
      })
    );
  };

  const logout = (cb) => {
    dispatch(
      signOut({
        resolve: (value) => {
          setCurrentUser(null)
          cb?.(value);
        },
        reject: () => {
          message.error('登陆失败');
        },
      })
    );
  };

  return (
    <AuthorizeContext.Provider
      value={{
        currentUser,
        login,
        logout,
        current
      }}
    >
      {children}
    </AuthorizeContext.Provider>
  );
});

export const useAuthorize = () => useContext(AuthorizeContext);
