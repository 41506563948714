import { useLayoutEffect } from 'react';

const StyleSortLayout = ({ children }) => {
  useLayoutEffect(() => {
    const head = document.querySelector('head');
      const tailWindStyleTags = [...head?.querySelectorAll('link')].filter(
        (style) => style.href.indexOf('application') > -1
      );
      tailWindStyleTags.forEach(tag=>{
        head?.insertAdjacentElement('afterbegin', tag);
      })
  }, []);

  return children;
};

export default StyleSortLayout;
