import { ReactElement } from 'react';
import { BugOutlined } from '@ant-design/icons';
import { useAuthorize } from '@/hooks/useAuthorize';

interface AuthorizedProps {
  authority: boolean | undefined;
  denied?: ReactElement | null;
  children?: ReactElement | null;
}

const defaultDenied = (
  <div className="flex w-full items-center text-center h-full justify-center border-red-600 border border-dashed text-red-600 rounded-md">
    <BugOutlined />
    无权限,请联系管理员
  </div>
);

const Authorized = ({ children, denied = defaultDenied, authority }) => {
  const { currentUser } = useAuthorize();
  return authority ? children : denied;
};

export default Authorized;
