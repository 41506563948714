import { Divider } from 'antd';
import dayjs from '@/utils/dayjs';
import { traversCrossingEvents } from '../utils';
import CurrentTimeBar from './CurrentTimeBar';
import Item from './Item';

const TodayItem = ({ events, today, minuteHeight, startHour }) => {
  let crossingIds: Array<number | string> = [];

  return (
    <div>
      {dayjs(today).isToday() && (
        <CurrentTimeBar
          today={today}
          minuteHeight={minuteHeight}
          step={60}
          startHour={startHour}
        />
      )}

      {events.map((event, i) => {
        const start = dayjs(event.start);
        const end = dayjs(event.end);
        const height =
          dayjs.duration(end.diff(start)).as('minutes') * minuteHeight;
        const formTop = dayjs.duration(start.diff(today)).as('minutes');

        const topSpace = formTop * minuteHeight;

        const slots = height / 60;

        const heightBorderFactor = slots * 1;

        const soltsFromTop = formTop / 60;

        const borderFactor = soltsFromTop + 1;

        const top = topSpace + borderFactor;

        const crossEvents = traversCrossingEvents(events, event);

        const alreadyRendered = crossEvents.filter((e) =>
          crossingIds.includes(e.id)
        );

        const offset = 10;

        crossingIds.push(event.id);
        const minHeight = height + heightBorderFactor
        return (
          <div
            key={i}
            className="event-item"
            style={{
              height: minHeight,
              top,
              width:
                alreadyRendered.length > 0
                  ? `calc(100% - ${100 - 98 / (alreadyRendered.length + 1)}%)`
                  : '100%',
              zIndex: events.length + i,
              left:
                alreadyRendered.length > 0
                  ? `${
                      (100 / (crossEvents.length + 1)) * alreadyRendered.length
                    }%`
                  : '',
                  borderRadius: 5,

            }}
          >
            {<Item event={event}  hideDate={minHeight < 35}/>}
          </div>
        );
      })}
    </div>
  );
};

export default TodayItem;
