import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Button, message, Select } from 'antd';
import Mark from '@/assets/images/mark.svg';
import { selectInvite } from '@/slice/selectors';
import { joinInvite, showInvite } from '@/slice/thunks';
import { useAuthorize } from '@/hooks/useAuthorize';

const { Option } = Select;

const Invites: FC<any> = () => {
  const {current} = useAuthorize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const invite = useSelector(selectInvite);
  const { token } = useParams();

  useEffect(() => {
    dispatch(showInvite({ token }));
  }, [dispatch]);

  const handleJoinInvite = useCallback(() => {
    dispatch(
      joinInvite({
        token,
        resolve() {
          current();
          message.success('欢迎加入');
          setTimeout(() => {
            navigate('/');
          }, 1000);
        },
      })
    );
  }, [dispatch, invite, token]);

  const renderButton = useCallback(() => {
    if (!invite) {
      return (
        <Button
          type="primary"
          className="w-full"
          onClick={(e) => {
            navigate('/');
          }}
        >
          返回
        </Button>
      );
    }
    if (invite?.login) {
      return (
        <Button type="primary" className="w-full" onClick={handleJoinInvite}>
          加入
        </Button>
      );
    }
    return (
      <Button
        type="primary"
        className="w-full"
        onClick={(e) => {
          localStorage.setItem('redirect', `/invites/${token}`);
          navigate('/users/login');
        }}
      >
        登陆 / 注册
      </Button>
    );
  }, [invite, token]);

  const renderContent = useCallback(() => {
    if (!invite) {
      return (
        <h2 className="w-full mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 py-10">
          邀请已过期
        </h2>
      );
    }
    return (
      <h2 className="w-full mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 py-10">
        {invite?.sender?.name} 邀请你加入 「{invite?.team?.title}」
      </h2>
    );
  }, [invite, token]);

  return (
    <div className="flex flex-1 flex-col items-center justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto h-20" src={Mark} alt="国立软件" />
        {renderContent()}
        <div className="space-y-6">{renderButton()}</div>
      </div>
    </div>
  );
};

export default Invites;
