import { useAuthorize } from "@/hooks/useAuthorize";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAppLoading, selectCurrentTeam } from "../slice/selectors";
import { modifyTeam } from "../slice/thunks";
import { Button, Input, Popconfirm, Tooltip, message } from "antd";

const Base = memo(() =>{


    const { current } = useAuthorize();
    const dispatch = useDispatch();
    const appLoading = useSelector(selectAppLoading);
    const currentTeam = useSelector(selectCurrentTeam);
    const [value, setValue] = useState('');

    useEffect(() => {
      setValue(currentTeam?.title);
    }, [currentTeam]);

    const handleUpdateTeam = useCallback(() => {
      dispatch(
        modifyTeam({
          ...currentTeam,
          title: value,
          resolve() {
            current();
            message.success('更新成功');
          },
        })
      );
    }, [dispatch, value]);

      return <div>
           <div className="py-3 font-bold select-none">
            <h6>基础信息</h6>
          </div>
          <div className="p-3 flex text-base border items-center justify-between">
            <div className="select-none">团队名称</div>
            <div className="">
              <Tooltip title="当前位置编辑即可更新团队名称">
                <Input
                  bordered={false}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </Tooltip>
            </div>
            <div className="">
              <Popconfirm title="更新团队名称" onConfirm={handleUpdateTeam}>
                <Button type="primary" loading={appLoading}>
                  更新
                </Button>
              </Popconfirm>
            </div>
          </div>
      </div>
  })

export default Base;
