import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Button, Input, message, Tag } from 'antd';
import {
  SearchOutlined,
  SettingOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import Authorized from '@/components/authorized';
import ColorTag from '@/components/color-tag';
import Table from '@/components/table';
import ToggleButton from '@/components/toggle-button';
import { TeamContext } from '../contexts';
import { selectMembers } from '../slice/selectors';
import { queryTeamUsers, updatePermissions } from '../slice/thunks';
import Invite from './form/Invite';
import Permission from './form/Permission';

const Members = () => {
  const { team_owner } = useContext(TeamContext);
  const dispatch = useDispatch();
  const members = useSelector(selectMembers);
  const { id } = useParams();
  const [params, setParams] = useState({
    page: 1,
    size: 10,
    name: '',
  });

  const [inviteVisible, setInviteVisible] = useState(false);

  const [permissionVisible, setPermissionVisible] = useState(false);

  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    fetchTeamUsers({ ...params, id });
  }, [params, id]);

  const fetchTeamUsers = useCallback(
    (params) => {
      dispatch(queryTeamUsers({ ...params }));
    },
    [dispatch]
  );

  const columns = [
    {
      title: '名字',
      dataIndex: 'name',
    },
    {
      title: '权限',
      dataIndex: 'teams',
      render(_, record, index) {
        return (
          <ColorTag
            options={{
              owner: {
                color: 'gold',
                children: '所有者',
              },
              admin: {
                color: 'lime',
                children: '管理员',
              },
              moderator: {
                color: 'green',
                children: '项目负责人',
              },
              author: {
                color: 'cyan',
                children: '普通用户',
              },
              banned: {
                color: 'red',
                children: '禁止使用',
              },
            }}
            value={record?.roles
              ?.filter(
                (role) =>
                  role.resource_id === id && role.resource_type === 'Team'
              )
              ?.map((role) => role.name)}
          />
        );
      },
    },
    {
      title: '设置',
      dataIndex: 'option',
      width: 100,
      render: (_, record, index) => (
        <Authorized authority={team_owner} denied={<></>}>
          <ToggleButton
            tipProps={{ title: '设置权限' }}
            buttonProps={{
              type: 'link',
              onClick: () => {
                setCurrentItem(record);
                setPermissionVisible(true);
              },
            }}
          >
            <SettingOutlined />
          </ToggleButton>
        </Authorized>
      ),
    },
  ];

  const handleSetPermission = useCallback(
    ({ id: user_id, permissions }) => {
      dispatch(
        updatePermissions({
          user_id,
          permissions: permissions.map(({ key, name }) => ({
            id: key,
            type: 'Project',
            name,
          })),
          resolve() {
            message.success('设置成功');
          },
        })
      );
    },
    [dispatch]
  );

  const handleReset = () => {
    setPermissionVisible(false);
    setCurrentItem(null);
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between">
        <Input
          prefix={<SearchOutlined />}
          style={{ width: 300 }}
          placeholder="搜索用户"
          onChange={(e) => {
            setParams({
              ...params,
              name: e.target.value,
            });
          }}
        />

        <Authorized authority={team_owner} denied={<></>}>
          <Button
            type="primary"
            icon={<ShareAltOutlined />}
            onClick={(e) => setInviteVisible(true)}
          >
            邀请成员
          </Button>
          <Invite
            open={inviteVisible}
            onCancel={(e) => setInviteVisible(false)}
          />
        </Authorized>
        <Authorized authority={team_owner && currentItem} denied={<></>}>
          <Permission
            open={permissionVisible}
            onCancel={handleReset}
            currentItem={currentItem}
            onOk={handleSetPermission}
          />
        </Authorized>
      </div>
      <Table
        className="p-2"
        rowKey="id"
        showHeader={false}
        columns={columns}
        dataSource={members.data}
        pagination={{
          pageSize: params?.size,
          onChange(page, pageSize) {
            setParams({ ...params, page, size: pageSize });
          },
          total: members.count,
        }}
      />
    </div>
  );
};

export default Members;
