import { useNavigate } from 'react-router';
import { Button } from 'antd';

const Error500 = () => {
  const navigate = useNavigate();
  return (
    <div className="flex w-full h-screen items-center justify-center flex-col">
      <h1 className="p-10">500 服务器异常</h1>
      <Button type="primary" onClick={(e) => navigate('/', { replace: true })}>
        首页
      </Button>
    </div>
  );
};

export default Error500;
