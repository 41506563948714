import { RootState } from "@/types";
import { createSelector } from "reselect";
import { initialState } from ".";




const selectRoot = (state: RootState) => state.team || initialState;


export const selectAppLoading = createSelector([selectRoot],teamState=>teamState.appLoading)

export const selectProjects = createSelector([selectRoot],teamState=>teamState.projects)

export const selectCurrentTeam = createSelector([selectRoot],teamState=>teamState.currentTeam)

export const selectCurrentProject = createSelector([selectRoot],teamState=>teamState.currentProject)

export const selectMembers = createSelector([selectRoot],teamState=>teamState.members)


export const selectLevels = createSelector([selectRoot],teamState=>teamState.levels)

