import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Button,
  Col,
  Form,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
} from 'antd';
import { RestOutlined, UndoOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import CheckGroup from '@/components/check-group';
import { selectProjects } from '../../slice/selectors';
import { createInvite, resetInvite } from '../../slice/thunks';
import options from './default-role';

const Invite = ({ ...props }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const projects = useSelector(selectProjects);

  const onReset = useCallback(async () => {
    await form.resetFields();
    await dispatch(resetInvite());
  }, [form, dispatch]);

  const onFinish = useCallback(
    (values) => {
      dispatch(
        createInvite({
          body: values,
          resolve({ data }) {
            copy(
              `${location.protocol}//${location.host}/#/invites/${data.token}`,
              { debug: true, message: 'Press #{key} to copy' }
            );
            message.success('链接复制剪切板');
          },
        })
      );
    },
    [dispatch]
  );

  return (
    <Modal title="邀请" {...props} footer={null} width={600} destroyOnClose>
      <Form
        form={form}
        onFinish={onFinish}
        preserve={false}
      >
        <Form.Item name="projects" noStyle>
          <CheckGroup
            title="选择参与的项目"
            options={projects?.data?.map((item) => ({
              label: item.title,
              value: item.id,
              ...item,
            }))}
            renderItem={(item) => {
              return (
                <div className="flex items-center justify-between">
                  <Avatar
                    shape="square"
                    style={{
                      backgroundColor: '#4F46E5',
                      verticalAlign: 'middle',
                    }}
                  >
                    {item.title[0]}
                  </Avatar>
                  <div className="px-2">{item.title}</div>
                </div>
              );
            }}
          />
        </Form.Item>
        <div className="flex items-center justify-between py-5">
          <Space>
            <Form.Item name="roles" noStyle>
              <Select
                options={options}
                placeholder="选择权限"
                className="min-w-[150px]"
              />
            </Form.Item>
            <span>有效期 7 天</span>
          </Space>
          <Space>
            <Form.Item noStyle>
              <Popconfirm title="重置所有邀请链接" onConfirm={onReset}>
                <Button icon={<UndoOutlined />}>重置链接</Button>
              </Popconfirm>
            </Form.Item>
            <Form.Item noStyle>
              <Button htmlType="submit" type="primary">
                复制链接
              </Button>
            </Form.Item>
          </Space>
        </div>
      </Form>
    </Modal>
  );
};

export default Invite;
