import MarkdownEditor from '@/components/markdown-editor';
import Tags from '@/components/tags';
import useDebounce from '@/hooks/useDebounce';
import { Form, Input, Progress, Tooltip } from 'antd';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import 'vditor/dist/index.css';
import { selectCurrentArticle, selectUsers } from '../slice/selectors';
import {
  changeCurrentArticle,
  createArticle,
  updateArticle,
} from '../slice/thunks';
import './index.scss';

type ArticleEditorProps = {
  style?: React.CSSProperties;
  className?: string;
  options?: any;
};
const ArticleEditor: React.FC<ArticleEditorProps> = memo(() => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const article = useSelector(selectCurrentArticle);
  const users = useSelector(selectUsers);

  useEffect(() => {
    if (article) {
      form.resetFields();
      form.setFieldsValue({ ...article, tags: article?.tags?.map(({ name }) => name) });
    }
  }, [article]);

  const { teamId, projectId } = useParams();

  const handleCreateArticle = useCallback(
    (obj) => {
      delete obj.created_at;
      delete obj.updated_at;
      delete obj.user_id;
      if (obj.id) {
        dispatch(
          updateArticle({
            ...obj,
            team_id: teamId,
            project_id: projectId,
            resolve({ value }) {
              dispatch(changeCurrentArticle(value));
            },
          })
        );
      } else {
        dispatch(
          createArticle({
            ...obj,
            team_id: teamId,
            project_id: projectId,
            resolve({ value }) {
              dispatch(changeCurrentArticle(value));
            },
          })
        );
      }
    },
    [dispatch]
  );


  const handleSave = useCallback(
    async (obj) => {
      await form.validateFields();
      await handleCreateArticle?.({ ...article, ...form.getFieldsValue(), ...obj });
    },
    [handleCreateArticle, form, article]
  );

  return (
    <div className={`h-[calc(100vh-50px)] overflow-auto`}>
      <div className=" absolute bottom-12  right-5 z-10">
      </div>
      <Form form={form} /***onValuesChange={handleReset}*/>
        <Form.Item name="title" noStyle>
          <Input
            bordered={false}
            placeholder="写下标题"
            className="h-16 font-bold"
            style={{ fontSize: 25 }}
          />
        </Form.Item>
        <Form.Item name="tags" noStyle>
          <Tags className="px-3 overflow-x-auto inline-block tags-list no-scrollbar " />
        </Form.Item>
        <Form.Item name="content">
          <MarkdownEditor
            users={users}
            onSave={(e) => handleSave({ ...e })}
            options={{
              toolbarConfig: {
                pin: true,
                hide: false,
              },
            }}
          />
        </Form.Item>
      </Form>
    </div>
  );
});

export default ArticleEditor;
