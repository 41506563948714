// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
import * as ActiveStorage from '@rails/activestorage'
ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'


// import './application.scss'


import { App } from '@/app'
import { createRoot } from 'react-dom/client'
import React from 'react'

const rootElement: any = document.getElementById("root");

// if(null !== rootElement){



const root = createRoot(rootElement);

root.render(
    <App />
)
// }else{
//     console.error("请先登陆有重试")
// }


// const app = new SvelteApp({
//     target: rootElement
// })

// export default app;
