
import { DragEvent } from "react";
import { EventItem } from "../types";

const useDrag = (event: EventItem) => {
  return {
    draggable: true,
    onDragStart: (e: DragEvent<HTMLElement>) => {
      e.stopPropagation();
      e.dataTransfer.setData("text/plain", `${event.id}`);
      e.currentTarget.style.backgroundColor = '#7d72f2';
      e.currentTarget.style.maxWidth = '100vh';
    },
    onDragEnd: (e: DragEvent<HTMLElement>) => {
      e.currentTarget.style.backgroundColor = event?.color || '#7d72f2';
    },
    onDragOver: (e: DragEvent<HTMLElement>) => {
      e.stopPropagation();
      e.preventDefault();
    },
    onDragEnter: (e: DragEvent<HTMLElement>) => {
      e.stopPropagation();
      e.preventDefault();
    },
  };
};

export default useDrag;
