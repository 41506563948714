import { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message, Tabs } from 'antd';
import { MultipleEvents } from '@/components/multiple-events';
import PageHeader from '@/components/page-header';
import Scheduler from '@/components/scheduler';
import { useAuthorize } from '@/hooks/useAuthorize';
import {
  selectAppLoading,
  selectEvents,
  selectProjectLevels,
  selectUsers,
} from '../slice/selectors';
import {
  createEvent,
  queryEvents,
  queryLevels,
  queryUsers,
  removeEvent,
  updateEvent,
} from '../slice/thunks';

const Events = memo((props) => {
  const { currentUser } = useAuthorize();
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const events = useSelector(selectEvents);
  const levels = useSelector(selectProjectLevels);
  const appLoading = useSelector(selectAppLoading);

  const handleFetchUsers = useCallback(() => {
    dispatch(queryUsers());
  }, [dispatch]);

  const handleFetchLevels = useCallback(() => {
    dispatch(queryLevels());
  }, [dispatch]);

  const handleFetchEvents = useCallback(
    (params) => {
      dispatch(queryEvents(params));
    },
    [dispatch]
  );

  useEffect(() => {
    handleFetchUsers();
    handleFetchLevels();
  }, []);

  const handleSaveEvent = useCallback(
    (values) => {
      if (values.id) {
        dispatch(
          updateEvent({
            ...values,
            resolve() {
              message.success('更新成功');
            },
          })
        );
      } else {
        dispatch(
          createEvent({
            ...values,
            resolve() {
              message.success('创建成功');
            },
          })
        );
      }
    },
    [dispatch]
  );

  const handleRemoveEvent = useCallback(
    (values) => {
      dispatch(
        removeEvent({
          ...values,
          resolve(value) {
            message.success('删除成功');
          },
        })
      );
    },
    [dispatch]
  );

  console.log('events');

  return (
    <div className="flex flex-col">
      <div className="bg-white p-5 mb-5 z-auto">
        <PageHeader
          title="时间管理"
          description="时间管理是指通过事先规划和运用一定的技巧、方法与工具实现对时间的灵活以及有效运用，从而实现个人或组织的既定目标的过程。"
        />
        {/* <Scheduler
          fetchEvents={handleFetchEvents}
          events={events}
          hours={[9, 18]}
          multiple={currentUser.is_admin}
          createEvents={handleSaveEvent}
          levels={levels}
          users={users}
          removeEvents={handleRemoveEvent}
          loading={appLoading}
        /> */}
        {/* <div className=" overflow-auto">

          <MultipleEvents />
        </div> */}

        <Tabs
          items={[
            {
              key: 'scheduler',
              label: '日历',
              children: (
                <Scheduler
                  fetchEvents={handleFetchEvents}
                  events={events}
                  hours={[9, 18]}
                  multiple={currentUser.is_admin}
                  createEvents={handleSaveEvent}
                  levels={levels}
                  users={users}
                  removeEvents={handleRemoveEvent}
                  loading={appLoading}
                  currentUser={currentUser.id}
                />
              ),
            },
            {
              key: 'board',
              label: '看板',
              children: (
                <div className=" overflow-auto">
                  <MultipleEvents
                    cancelDrop={(e) => {
                      console.log(e);
                      return false;
                    }}
                  />
                </div>
              ),
            },
          ]}
        />
      </div>
    </div>
  );
});

export default memo(Events);
