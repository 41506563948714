import { useMemo } from 'react';
import { Typography } from 'antd';
import {
  closestTo,
  differenceInCalendarWeeks,
  differenceInDays,
  isWithinInterval,
} from '../utils/date-fns';
import dayjs from '@/utils/dayjs';
import { filterMonthEvents } from '../utils';
import Item from './Item';

const MonthItem = ({ events, today, days, cellHeight, weeks, onViewMore }) => {
  const offset = Math.round((cellHeight - 27) / 28 - 1);

  const firstDay = weeks.some((week) => week.isSame(today)) ? today : null;

  const filterEvents = filterMonthEvents(events, firstDay, today);

  const renderEvents = useMemo(() => {
    let renderMore = false;
    return filterEvents.map((event, i) => {
      let startEvent = dayjs(event.start);
      const end = dayjs(event.end);
      const fromPrevWeek = !!firstDay && startEvent.isBefore(firstDay);

      let start = fromPrevWeek && firstDay ? firstDay : startEvent;

      let eventSize = dayjs.duration(end.diff(start)).get('day') + 1;

      const toNextWeek = differenceInCalendarWeeks(event.end, start) > 0;

      if (toNextWeek) {
        const notAccurateWeekStart = startEvent.startOf('week');
        const closestStart = closestTo(notAccurateWeekStart, weeks);

        if (closestStart) {
          eventSize =
            days.length -
            (!firstDay
              ? dayjs.duration(startEvent.diff(closestStart)).get('day')
              : 0);
        }
      }

      const prevNextEvents = events.filter((e) => {
        const start = dayjs(e.start);
        const end = dayjs(e.end);
        const daysDiff = differenceInDays(start, end);

        const moreThanOneDay = daysDiff > 0;

        const isWithinToday =
          (daysDiff === 0 &&
            isWithinInterval(start, {
              start: today.startOf('day'),
              end: today.endOf('day'),
            })) ||
          isWithinInterval(end, {
            start: today.startOf('day'),
            end: today.endOf('day'),
          });

        const isBeforeToday = start.isBefore(today.add(1,'minutes'));
        const isAfterToday = end.isAfter(today.add(1,'minutes'));

        const isBeforeAfter = isBeforeToday && isAfterToday;
        const isBeforeCrossToday = isBeforeToday && isWithinToday;

        const isAfterCrossToday = isAfterToday && isWithinToday;

        const isFromTodayOn = isWithinToday && moreThanOneDay;

        const shouldInclude =
          isBeforeAfter ||
          isBeforeCrossToday ||
          isAfterCrossToday ||
          isFromTodayOn;

        return !firstDay && e.id !== event.id && offset > i && shouldInclude;
      });

      let index = i;

      if (prevNextEvents.length) {
        index += prevNextEvents.length;
      }

      const topSpace = Math.min(index, offset) * 28 + 27;

      if(renderMore) return;

      if (index >= offset) {
        renderMore = true
        return (
          <Typography.Link
            key={i}
            className="text-left absolute bottom-0"
            onClick={(e) => onViewMore(dayjs(event.start))}
            style={{ fontSize: 11 }}
          >
            ({`${Math.abs(filterEvents.length - i)}`}) 更多
          </Typography.Link>
        );
      }

      return (
        <div
          key={i}
          className="month-item"
          style={{
            left: 0,
            top: topSpace,
            width: `${100 * eventSize}%`,
            height: 25,
            borderRadius: 5,
          }}
        >
          <Item event={event} hideDate />
        </div>
      );
    });
  }, [
    events,
    today,
    days,
    cellHeight,
    onViewMore,
    filterEvents,
    offset,
    weeks,
  ]);

  return <div>{renderEvents}</div>;
};

export default MonthItem;
