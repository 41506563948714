import { useCallback, useEffect } from 'react';
import { Button, Drawer, Form, Input, Modal, Space } from 'antd';

const ProjectForm = ({ currentItem, onSubmit, onClose, ...props }) => {
  const title = currentItem ? '修改项目' : '创建项目';
  const [form] = Form.useForm();

  const handleSubmit = useCallback(async () => {
    await form.validateFields();
    onSubmit?.({ ...currentItem, ...form.getFieldsValue() });
    onClose?.();
    await form.resetFields();
  }, [form, currentItem]);

  const handleClose = useCallback(async () => {
    await onClose?.();
    await form.resetFields();
  }, [form]);

  useEffect(() => {
    if (currentItem) {
      form.setFieldsValue(currentItem);
    }
  }, [currentItem, form, props]);

  return (
    <Modal
      {...props}
      onCancel={handleClose}
      destroyOnClose
      title={title}
      onOk={handleSubmit}
    >
      <Form layout="vertical" form={form}>
        <Form.Item label="项目名称" name="title" required>
          <Input placeholder="请输入项目名称" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProjectForm;
