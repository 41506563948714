import React from 'react';
import { Tag } from 'antd';

export const Colors = [
  '#F5222D4D',
  '#FA8C164D',
  '#FADB144D',
  '#8BBB114D',
  '#52C41A4D',
  '#13A8A84D',
  '#1677FF4D',
  '#2F54EB4D',
  '#722ED14D',
  '#EB2F964D',
];

interface ColorTagProps {
  options?: any;
  value: string[];
}

const ColorTag = ({ options, value }: ColorTagProps) => {
  return <div>
    {value.map((item,i) => <Tag  key={i} {...options[item]} />)}
  </div>;
};

export default ColorTag;
