import dayjs from '@/utils/dayjs';
import { EventItem } from '../types';
import {
  eachMinuteOfInterval,
  eachWeekOfInterval,
  isWithinInterval,
} from './date-fns';

export const monthWithWeek = ({ currentDate }) => {
  const start = dayjs(currentDate).startOf('month');
  const end = dayjs(currentDate).endOf('month');
  return [start, end, eachWeekOfInterval({ start, end })];
};

export const dayWithMinute = ({ currentDate, hours }) => {
  const start = dayjs(currentDate).startOf('day').hour(hours[0]);
  const end = dayjs(currentDate).endOf('day').hour(hours[1]);
  return [start, end, eachMinuteOfInterval({ start, end })];
};

export const sortBy = (a,b)=>{
  const aStart = dayjs(a.start)
  const aEnd = dayjs(a.end)


  const bStart = dayjs(b.start)
  const bEnd = dayjs(b.end)

  const aDiff = dayjs.duration(aEnd.diff(aStart)).as("day");
  const bDiff = dayjs.duration(bEnd.diff(bStart)).as("day");

  return bDiff - aDiff;
}


export const filterHeaderEvents = (events, date) => {
  return events.filter((event, i) => {
    const start = dayjs(event.start).startOf('day');
    const end = dayjs(event.end).endOf('day');
    let result = dayjs.duration(end.diff(start)).get('day') > 0;

    if (!result) return result;

    if (Array.isArray(date)) {
      result = date.some((d) =>
        isWithinInterval(d, {
          start,
          end,
        })
      );
    } else {
      result = isWithinInterval(date, {
        start,
        end,
      });
    }

    return result;
  });
};

export const filterTodayEvents = (events, date) => {
  return events
    .filter((event, i) => {
      const start = dayjs(event.start).startOf('day');
      const end = dayjs(event.end).endOf('day');
      let result = dayjs.duration(end.diff(start)).get('day') > 0;
      return !result && date.isSame(start, 'day');
    })
    .sort(sortBy);
};

export const traversCrossingEvents = (
  todayEvents: EventItem[],
  event: EventItem
): EventItem[] => {
  return todayEvents.filter(
    (e) =>
      e.id !== event.id &&
      (isWithinInterval(dayjs(event.start), {
        start: dayjs(e.start),
        end: dayjs(e.end),
      }) ||
        isWithinInterval(dayjs(event.end), {
          start: dayjs(e.start),
          end: dayjs(e.end),
        }) ||
        isWithinInterval(dayjs(e.start), {
          start: dayjs(event.start),
          end: dayjs(event.end),
        }) ||
        isWithinInterval(dayjs(e.end), {
          start: dayjs(event.start),
          end: dayjs(event.end),
        }))
  );
};

export const filterMonthEvents = (events, firstDay, today) => {
  return events
    .filter((event, i) => {
      const start = dayjs(event.start).startOf('day');
      const end = dayjs(event.end).endOf('day');
      return (
        firstDay &&
        isWithinInterval(firstDay, { start, end }) ||
        today.isSame(start, 'day')
      );
    }).sort(sortBy);
};
