import Mark from '@/assets/images/mark.svg';
import { useAuthorize } from '@/hooks/useAuthorize';
import { Button, Checkbox, Form, Input } from "antd";
import { useCallback } from 'react';
import { useNavigate, useParams } from "react-router";
import SharedLink from "./shard-link";

const Login = ({appLoading}) =>{
    const {redirect} = useParams();
    const navigate = useNavigate();
    const {login} = useAuthorize();

    const handleSignIn = useCallback((values) =>{
        login(values,()=>{
            navigate(redirect ? redirect : '/')
        });
    },[navigate,redirect])


    return <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8   dark:text-white">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img className="mx-auto h-10" src={Mark} alt="国立软件" />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900  dark:text-white">登陆</h2>
        </div>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm ">
            <Form layout="vertical" onFinish={handleSignIn}>
                <Form.Item label="用户名" name="username" rules={[{ required: true }]}>
                    <Input placeholder="请输入用户名"/>
                </Form.Item>
                <Form.Item label="密码" name="password" rules={[{ required: true }]}>
                    <Input.Password placeholder="请输入密码"/>
                </Form.Item>
                <Form.Item>
                    <Form.Item name="remember_me" valuePropName="checked" noStyle>
                        <Checkbox className="w-2/4">30天不需要重新登录</Checkbox>
                    </Form.Item>
                    <Button type="link" className="w-2/4 text-right" onClick={()=>navigate('/users/forget-password')}>忘记密码?</Button>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" className="w-full" htmlType='submit' loading={appLoading}>登陆</Button>
                </Form.Item>
            </Form>
            <SharedLink/>
        </div>
    </div>
}


export default Login;
