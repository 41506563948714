import { memo } from 'react';
import SplitPanel from '@/components/split-panel';
import ArticleEditor from './ArticleEditor';
import './index.scss';
import Sidebar from './Sidebar';

const Item = SplitPanel.Item;

const Articles = memo(() => {
  return (
    <SplitPanel
      options={{ minWidths: [20, 80], maxWidths: [25, 75], split: 'x' }}
    >
      <Item>
        <Sidebar />
      </Item>

      <Item>
        <div className="bg-white z-10">
          <ArticleEditor />
        </div>
      </Item>
    </SplitPanel>
  );
});

export default Articles;
