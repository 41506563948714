import { useEffect, useState } from 'react';
import { Divider, Tooltip } from 'antd';
import { differenceInMinutes } from '../utils/date-fns';
import dayjs from '@/utils/dayjs';

const calculateTop = ({ today, startHour, step, minuteHeight }) => {
  const now = dayjs();
  const minutesFromStep = differenceInMinutes(now, today);

  const topSpace = minutesFromStep * minuteHeight;
  const slotsFromTop = minutesFromStep / step;

  const borderFactor = slotsFromTop + 1;

  const top = topSpace + borderFactor;

  return top;
};

const CurrentTimeBar = (props) => {
  const [top, setTop] = useState(calculateTop(props));

  useEffect(() => {
    const interval = setInterval(() => setTop(calculateTop(props)), 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  // Prevent showing bar on top of days/header
  if (top < 0) return null;

  return (
    <Tooltip
      title={`${dayjs().format('YYYY-MM-DD HH:mm')}`}
      placement="topLeft"
    >
      <div
        className="absolute text-center w-full top-2 z-10 flex items-center"
        style={{ top: top - 5 }}
      >
        <div className="h-[15px] w-[15px] bg-red-500 hover:h-[17px] hover:w-[17px] hover:bg-red-600 rounded-xl border-red-100"></div>
        <div className="h-[2px] bg-red-500 w-full"></div>
      </div>
    </Tooltip>
  );
};

export default CurrentTimeBar;
