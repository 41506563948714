import { Invite, Level, PageData, Project, RootState, Team, User } from '@/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { request } from '@/utils/request';
import { teamActions } from '.';
import {
  ChangeProjectParams,
  ChangeTeamParams,
  CreateLevelParams,
  FavoriteParams,
  InviteParams,
  ModifyProjectParams,
  PermissionParams,
  QueryProjectParams,
  QueryTeamParams,
  QueryTeamUsersParams,
  RemoveLevelParams,
  UpdateLevelParams,
} from './types';

export const getTeam = createAsyncThunk<Team>('team/current', async () => {
  const { data } = await request<Team>('/api/v1/teams/current');
  return data;
});



export const modifyTeam = createAsyncThunk<Team, ChangeTeamParams>(
  'team/modifyTeam',
  async ({ reject, resolve, ...team }) => {
    const { data } = await request<Project>(
      `/api/v1/teams/${team.id}`,
      {
        method: 'PUT',
        data: team,
      },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);

export const queryProjects = createAsyncThunk<
  PageData<Project>,
  QueryProjectParams
>('team/queryProjects', async (params) => {
  const data = await request<PageData<Project>>('/api/v1/projects', {
    method: 'GET',
    params,
  });
  return data;
});


export const createProject = createAsyncThunk<Project, QueryProjectParams>(
  'team/createProject',
  async ({ title, reject, resolve }) => {
    const { data } = await request<Project>(
      '/api/v1/projects',
      {
        method: 'POST',
        data: { title },
      },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);

export const removeProject = createAsyncThunk<Project, ModifyProjectParams>(
  'team/removeProject',
  async ({ reject, resolve, ...project }) => {
    const { data } = await request<Project>(
      `/api/v1/projects/${project.id}`,
      {
        method: 'DELETE',
        data: project,
      },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);

export const favoriteProject = createAsyncThunk<Project, FavoriteParams>(
  'team/favoriteProject',
  async ({ reject, resolve, ...project }) => {
    const { data } = await request<Project>(
      `/api/v1/favorites`,
      {
        method: 'POST',
        data: { type: 'Project', id: project.id },
      },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);

export const unfavoriteProject = createAsyncThunk<Project, FavoriteParams>(
  'team/unfavoriteProject',
  async ({ reject, resolve, ...project }) => {
    const { data } = await request<Project>(
      `/api/v1/favorites/${project.id}`,
      {
        method: 'DELETE',
        data: { type: 'Project' },
      },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);

export const modifyProject = createAsyncThunk<Project, ModifyProjectParams>(
  'team/modifyProject',
  async ({ reject, resolve, ...project }) => {
    const { data } = await request<Project>(
      `/api/v1/projects/${project.id}`,
      {
        method: 'PUT',
        data: project,
      },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);

export const switchPrimaryProject = createAsyncThunk<
  Project,
  ChangeProjectParams,
  { state: RootState }
>(
  'team/switchPrimaryProject',
  async ({ id, reject, resolve }, { getState, dispatch }) => {
    const { data } = await request<Project>(
      `/api/v1/projects/${id}/primary`,
      { method: 'POST' },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    localStorage.setItem('current-project', JSON.stringify(data));
    dispatch(teamActions.switchCurrentProject(data));
    return data;
  }
);

export const switchPrimaryTeam = createAsyncThunk<
  Team,
  ChangeTeamParams,
  { state: RootState }
>(
  'team/switchPrimaryTeam',
  async ({ id, reject, resolve }, { getState, dispatch }) => {
    localStorage.setItem('params', id);
    const { data } = await request<Team>(
      `/api/v1/teams/${id}/primary`,
      { method: 'POST' },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    dispatch(teamActions.switchCurrentTeam(data));
    return data;
  }
);

export const changeCurrentProject = createAsyncThunk<
  Project,
  Project,
  { state: RootState }
>('team/changeCurrentProject', async (project, { getState, dispatch }) => {
  dispatch(teamActions.switchCurrentProject(project));
  return project;
});

export const queryTeamUsers = createAsyncThunk<
  PageData<User>,
  QueryTeamUsersParams
>('team/queryTeamUsers', async (params) => {
  const data = await request<PageData<User>>(
    `/api/v1/teams/${params.id}/users`,
    {
      method: 'GET',
      params,
    }
  );
  return data;
});

export const createInvite = createAsyncThunk<Invite, InviteParams>(
  'team/createInvite',
  async ({ resolve, reject, ...invite }) => {
    const data = await request<Invite>(
      `/api/v1/invites`,
      {
        method: 'POST',
        data: invite,
      },
      {
        onFulfilled: resolve,
      }
    );
    return data;
  }
);

export const resetInvite = createAsyncThunk<Invite>(
  'team/resetInvite',
  async () => {
    const data = await request<Invite>(`/api/v1/invites/reset`, {
      method: 'POST',
    });
    return data;
  }
);


export const showPermissions = createAsyncThunk<User, PermissionParams>(
  'team/createInvite',
  async ({ resolve, reject, user_id }) => {
    const data = await request<User>(
      `/api/v1/permissions/${user_id}`,
      {
        method: 'GET',
      },
      {
        onFulfilled: resolve,
      }
    );
    return data;
  }
);


export const updatePermissions = createAsyncThunk<User, PermissionParams>(
  'team/createInvite',
  async ({ resolve, reject, user_id,permissions }) => {
    const data = await request<User>(
      `/api/v1/permissions/${user_id}`,
      {
        method: 'PUT',
        data: {permissions},
      },
      {
        onFulfilled: resolve,
      }
    );
    return data;
  }
);


export const queryLevels = createAsyncThunk<PageData<Level>, QueryTeamParams>(
  'team/queryLevels',
  async ({ reject,resolve,...params  }) => {
    const data = await request<PageData<Level>>(
      '/api/v1/levels',
      {
        method: 'GET',
        params,
      },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);



export const modifyLevel = createAsyncThunk<Level, UpdateLevelParams>(
  'team/modifyLevel',
  async ({ reject,resolve,...level  }) => {
    const { data } = await request<Level>(
      `/api/v1/levels/${level.id}`,
      {
        method: 'PUT',
        data: level,
      },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);


export const createLevel = createAsyncThunk<Level, CreateLevelParams>(
  'team/createLevel',
  async ({ reject,resolve,...level  }) => {
    const { data } = await request<Level>(
      '/api/v1/levels',
      {
        method: 'POST',
        data: level,
      },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);

export const removeLevel = createAsyncThunk<Level, RemoveLevelParams>(
  'team/removeLevel',
  async ({ reject, resolve, ...level }) => {
    const { data } = await request<Level>(
      `/api/v1/levels/${level.id}`,
      {
        method: 'DELETE',
        data: level,
      },
      {
        onRejected: reject,
        onFulfilled: resolve,
      }
    );
    return data;
  }
);
