import { useCallback, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Empty, message, Modal, Tag } from 'antd';
import { Level } from '@/types';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import VirtualList from 'rc-virtual-list';
import ToggleButton from '@/components/toggle-button';
import { selectLevels } from '../slice/selectors';
import {
  createLevel,
  modifyLevel,
  queryLevels,
  removeLevel,
} from '../slice/thunks';
import LevelForm from './form/LevelForm';
import './index.scss';

let ContainerHeight = 400;
const LevelPanel = () => {
  const dispatch = useDispatch();
  const levels = useSelector(selectLevels);
  const [params, setParams] = useState({
    page: 1,
    size: 20,
  });

  const [selectedKey, setSelectedKey] = useState('');
  const [visible, setVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  useLayoutEffect(() => {
    fetchLevels(params);
  }, []);

  const fetchLevels = useCallback(
    (params) => {
      dispatch(queryLevels({ ...params }));
    },
    [dispatch]
  );

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      ContainerHeight
    ) {
      if (levels.data.length < levels.count) {
        setParams((pre) => {
          const newState = { ...pre, page: pre.page + 1 };
          fetchLevels(newState);
          return newState;
        });
      }
    }
  };

  const handleCreateLevel = useCallback(
    (level) => {
      console.log('level', level);
      if (level.id) {
        dispatch(
          modifyLevel({
            ...level,
            resolve() {
              message.success('修改成功');
              handleResetItem();
            },
          })
        );
      } else {
        dispatch(
          createLevel({
            ...level,
            resolve() {
              message.success('添加成功');
              handleResetItem();
            },
          })
        );
      }
    },
    [dispatch]
  );

  const handleDeleteLevel = useCallback(
    (level) => {
      dispatch(
        removeLevel({
          ...level,
          resolve() {
            message.success('删除成功');
          },
        })
      );
    },
    [dispatch]
  );

  const handleResetItem = () => {
    setVisible(false);
    setCurrentItem(null);
  };

  const handleChangeItem = (level) => {
    setVisible(true);
    setCurrentItem(level);
  };

  return (
    <div>
      <div className="py-3 font-bold select-none flex items-center justify-between">
        <h6>任务标签</h6>
        <Button type="primary" onClick={(e) => setVisible(true)}>
          添加
        </Button>
      </div>
      <div className="p-3">
        <div className="levels-list h-[400px]" id="levels-list">
          {levels?.data?.length ? (
            <VirtualList
              data={levels?.data}
              height={ContainerHeight}
              itemHeight={80}
              itemKey="id"
              onScroll={onScroll}
            >
              {(item: Level) => (
                <div
                  className={`relative group item  ${
                    selectedKey == item.id ? 'selected' : ''
                  }`}
                  onClick={(e) => {
                    // handleChangeArticle(item);
                  }}
                  onMouseEnter={(e) => {}}
                >
                  <div className=" absolute right-0">
                    <ToggleButton
                      tipProps={{ title: '编辑标签' }}
                      buttonProps={{
                        type: 'link',
                        size: 'small',
                        onClick: () => {
                          handleChangeItem(item);
                        },
                      }}
                    >
                      <EditOutlined />
                    </ToggleButton>
                    <ToggleButton
                      tipProps={{ title: '删除标签' }}
                      buttonProps={{
                        type: 'link',
                        size: 'small',
                        onClick: () =>
                          Modal.confirm({
                            title: '删除标签？',
                            onOk() {
                              handleDeleteLevel(item);
                            },
                            okButtonProps: {
                              type: 'primary',
                            },
                          }),
                      }}
                    >
                      <DeleteOutlined
                        style={{
                          fontSize: '16px',
                          width: '16px',
                          height: '16px',
                        }}
                      />
                    </ToggleButton>
                  </div>
                  <div className="item-title">
                    <Tag color={item.color} bordered={false}>
                      {item.label}
                    </Tag>
                  </div>
                  <div className="item-content ">{item.remark}</div>
                </div>
              )}
            </VirtualList>
          ) : (
            <Empty description={'没有数据'} />
          )}
        </div>
      </div>
      {visible && (
        <LevelForm
          open={visible}
          currentItem={currentItem}
          onCancel={handleResetItem}
          onOk={handleCreateLevel}
        />
      )}
    </div>
  );
};

export default LevelPanel;
