import { useCallback, useContext } from 'react';
import { Button, Form, Modal, Select, Space } from 'antd';
import MultipleSelect from '@/components/multiple-select';
import { TeamContext } from '../../contexts';
import options from './default-role';

const Permission = ({ currentItem, onCancel, onOk, ...props }) => {
  const { projects = [], roles = [] } = currentItem;

  const [form] = Form.useForm();
  const handleSubmit = useCallback(
    () => {
      onOk?.({ id: currentItem?.id, ...form.getFieldsValue() });
      handleCancel();
    },
    [form,onOk]
  );

  const handleCancel = useCallback(() => {
    form.resetFields();
    onCancel();
  }, [form, onCancel]);

  return (
    <Modal
      title="设置权限"
      {...props}
      okText="保存"
      onCancel={handleCancel}
      onOk={handleSubmit}
      destroyOnClose
    >
      <Form
        form={form}
      >
        <Form.Item name="permissions">
          <MultipleSelect
            headers={['项目', '权限']}
            options={projects.map((project) => {
              let role_name = roles?.find(
                ({ resource_id, resource_type }) =>
                  resource_type === 'Project' && resource_id === project.id
              )?.name;
              return {
                label: project.title,
                key: project.id,
                options: options,
                value: role_name,
                disabled: role_name === 'owner',
              };
            })}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Permission;
