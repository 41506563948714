import { memo, useContext, useEffect } from 'react';
import { Avatar, Tabs } from 'antd';
import { SchedulerContext } from './context';

const TabHeaders = memo(({ children }) => {
  const { users, currentUser, handleSetState } = useContext(SchedulerContext);

  const items = users?.map(({ id, username, name, avatar_url }) => ({
    key: id,
    label: (
      <div className="flex items-center  w-[150px]">
        <Avatar src={avatar_url} className="dark:bg-indigo-500 dark:text-white">
          {name[0]}
        </Avatar>
        <div className="px-2">{name || username}</div>
      </div>
    ),
    children: currentUser === id ? children : null,
  }));

  return (
    <Tabs
      size="small"
      activeKey={currentUser}
      items={items}
      className="bg-white overflow-hidden dark:bg-slate-800 dark:text-white"
      tabBarStyle={{ margin: 0 }}
      onChange={(e) => {
        handleSetState?.({ currentUser: e });
      }}
    />
  );
});

export default TabHeaders;
