import { Article } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer } from '@/utils/@reduxjs/injectReducer';
import {
  changeCurrentArticle,
  createArticle,
  createEvent,
  createTag,
  queryArticles,
  queryEvents,
  queryLevels,
  queryTags,
  queryUsers,
  removeArticle,
  removeEvent,
  removeTag,
  unfavoriteArticle,
  updateArticle,
  updateEvent,
} from './thunks';
import { favoriteArticle } from './thunks';
import { ProjectState } from './types';

export const initialState: ProjectState = {
  appLoading: false,
  currentArticle: null,
  articles: { data: [], count: 0 },
  tags: [],
  users: [],
  projectLevels:[],
  events: [],
  eventSettings: {}
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setCurrentArticle(state, action: PayloadAction<Article>) {
      state.currentArticle = action.payload;
    },
    setEventSettings(state, action: PayloadAction<any>) {
      state.eventSettings = action.payload;
    },
  },
  extraReducers(builder) {
    //query articles
    builder.addCase(queryArticles.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(queryArticles.fulfilled, (state, action) => {
      state.appLoading = false;
      state.articles.count = action.payload.count;
      state.articles.data = Object.values([ ...state.articles.data,...action.payload.data].reduce((acc,obj)=>({...acc,[obj.id]:obj}),{}));
    });
    builder.addCase(queryArticles.rejected, (state, action) => {
      state.appLoading = false;
    });

    //create article
    builder.addCase(createArticle.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(createArticle.fulfilled, (state, action) => {
      state.appLoading = false;
      state.articles.data.unshift(action.payload);
      state.articles.count += 1;
      state.currentArticle = action.payload
    });
    builder.addCase(createArticle.rejected, (state, action) => {
      state.appLoading = false;
    });

    //update article
    builder.addCase(updateArticle.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(updateArticle.fulfilled, (state, action) => {
      state.appLoading = false;
      state.articles.data = state?.articles?.data?.map((row) => {
        if (row.id === action.payload.id) {
          return { ...row, ...action.payload };
        }
        return row;
      });
      state.currentArticle = action.payload
    });
    builder.addCase(updateArticle.rejected, (state, action) => {
      state.appLoading = false;
    });

    //remove article
    builder.addCase(removeArticle.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(removeArticle.fulfilled, (state, action) => {
      state.appLoading = false;
      state.articles.data = state?.articles?.data?.filter(
        (row) => row.id !== action.payload.id
      );
      state.articles.count -= 1;
    });
    builder.addCase(removeArticle.rejected, (state, action) => {
      state.appLoading = false;
    });

    //favorite article
    builder.addCase(favoriteArticle.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(favoriteArticle.fulfilled, (state, action) => {
      state.appLoading = false;
      state.articles.data = state?.articles?.data?.map((row) => {
        if (row.id === action.payload.id) {
          return { ...row, favorite: true };
        }
        return row;
      });
    });
    builder.addCase(favoriteArticle.rejected, (state, action) => {
      state.appLoading = false;
    });



    //unfavorite article
    builder.addCase(unfavoriteArticle.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(unfavoriteArticle.fulfilled, (state, action) => {
      state.appLoading = false;
      state.articles.data = state?.articles?.data?.map((row) => {
        if (row.id === action.payload.id) {
          return { ...row, favorite: false };
        }
        return row;
      });
    });
    builder.addCase(unfavoriteArticle.rejected, (state, action) => {
      state.appLoading = false;
    });

    //query tags
    builder.addCase(queryTags.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(queryTags.fulfilled, (state, action) => {
      state.appLoading = false;
      state.tags = action.payload;
    });
    builder.addCase(queryTags.rejected, (state, action) => {
      state.appLoading = false;
    });

    //create tag
    builder.addCase(createTag.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(createTag.fulfilled, (state, action) => {
      state.appLoading = false;
      state?.tags?.unshift?.(action.payload);
    });
    builder.addCase(createTag.rejected, (state, action) => {
      state.appLoading = false;
    });

    //remove tag
    builder.addCase(removeTag.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(removeTag.fulfilled, (state, action) => {
      state.appLoading = false;
      state.tags = state?.tags?.filter((row) => row.id !== action.payload.id);
    });
    builder.addCase(removeTag.rejected, (state, action) => {
      state.appLoading = false;
    });


    //query users
    builder.addCase(queryUsers.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(queryUsers.fulfilled, (state, action) => {
      state.appLoading = false;
      state.users = action.payload;
    });
    builder.addCase(queryUsers.rejected, (state, action) => {
      state.appLoading = false;
    });


    //query levels
    builder.addCase(queryLevels.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(queryLevels.fulfilled, (state, action) => {
      state.appLoading = false;
      state.projectLevels = action.payload;
    });
    builder.addCase(queryLevels.rejected, (state, action) => {
      state.appLoading = false;
    });



    //query events
    builder.addCase(queryEvents.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(queryEvents.fulfilled, (state, action) => {
      state.appLoading = false;
      state.events = action.payload;
    });
    builder.addCase(queryEvents.rejected, (state, action) => {
      state.appLoading = false;
    });

    //create event
    builder.addCase(createEvent.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(createEvent.fulfilled, (state, action) => {
      state.appLoading = false;
      state.events.unshift(action.payload);
    });
    builder.addCase(createEvent.rejected, (state, action) => {
      state.appLoading = false;
    });

    //update event
    builder.addCase(updateEvent.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(updateEvent.fulfilled, (state, action) => {
      state.appLoading = false;
      state.events = state?.events?.map((row) => {
        if (row.id === action.payload.id) {
          return { ...row, ...action.payload };
        }
        return row;
      });
    });
    builder.addCase(updateEvent.rejected, (state, action) => {
      state.appLoading = false;
    });

    //remove event
    builder.addCase(removeEvent.pending, (state, action) => {
      state.appLoading = true;
    });
    builder.addCase(removeEvent.fulfilled, (state, action) => {
      state.appLoading = false;
      state.events = state?.events?.filter(
        (row) => row.id !== action.payload.id
      );
    });
    builder.addCase(removeEvent.rejected, (state, action) => {
      state.appLoading = false;
    });
  },
});

export const { actions: projectActions, reducer } = slice;

export const useProjectSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
