import Authorized from '@/components/authorized';
import ColorTag from '@/components/color-tag';
import Loading from '@/components/loading';
import PageHeader from '@/components/page-header';
import { useAuthorize } from '@/hooks/useAuthorize';
import { Tabs } from 'antd';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { TeamContext } from './contexts';
import Members from './members';
import Projects from './projects';
import Settings from './settings';
import { selectCurrentTeam } from './slice/selectors';

const Team = memo(() => {
  const { currentUser } = useAuthorize();
  const currentTeam = useSelector(selectCurrentTeam);
  const { id } = useParams();

  const team_roles = currentUser?.roles?.filter(
    (role) => role.resource_id === id && role.resource_type === 'Team'
  );
  const team_owner =
    currentUser?.teams?.some((team) => team.id === id && team.owned) ||
    team_roles?.some((role) => role.name === 'owner');

  return (
    <TeamContext.Provider value={{ team_roles, team_owner }}>
      <Authorized authority={currentTeam} denied={<Loading />}>
        <div className="flex flex-col h-full bg-white px-5">
          <PageHeader
            title={
              <div className="flex  items-center justify-start">
                <div className=" pr-2">{currentTeam?.title}</div>
                <ColorTag
                  options={{
                    owner: {
                      color: 'gold',
                      children: '所有者',
                    },
                    admin: {
                      color: 'lime',
                      children: '管理员',
                    },
                    moderator: {
                      color: 'green',
                      children: '项目负责人',
                    },
                    author: {
                      color: 'cyan',
                      children: '普通用户',
                    },
                    banned: {
                      color: 'red',
                      children: '禁止使用',
                    },
                  }}
                  value={currentUser?.roles
                    ?.filter(
                      (role) =>
                        role.resource_id === id && role.resource_type === 'Team'
                    )
                    ?.map((role) => role.name)}
                />
              </div>
            }
            description="在这里进行团队设置"
          />
          <Tabs
            // tabIndex={tab}
            // onChange={(e) => setTab(e)}
            size="large"
            items={[
              { label: '项目', key: 'projects', children: <Projects /> },
              {
                label: '成员',
                key: 'members',
                children: (
                  <Authorized authority={team_owner}>
                    <Members />
                  </Authorized>
                ),
              },
              {
                label: '设置',
                key: 'settings',
                children: (
                  <Authorized authority={team_owner}>
                    <Settings />
                  </Authorized>
                ),
              },
            ]}
          />
        </div>
      </Authorized>
    </TeamContext.Provider>
  );
});

export default Team;
