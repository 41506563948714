import { Avatar, Button, Form, Input, Upload } from 'antd';
import { useAuthorize } from '@/hooks/useAuthorize';

const Profile = () => {
  const { currentUser } = useAuthorize();
  return (
    <div className="pb-12 w-96">
    <h2 className="text-base font-semibold leading-7 text-gray-900">
      个人资料
    </h2>
    <Form layout="vertical" initialValues={currentUser}>
      <Form.Item name="avatar_url" valuePropName="src">
        <Avatar size={64} />
      </Form.Item>
      <Form.Item label="名字" name="name">
        <Input placeholder="请输入名字" />
      </Form.Item>
      <Form.Item label="邮箱" name="email">
        <Input placeholder="请输入邮箱" />
      </Form.Item>
      <Form.Item label="个人网站" name="web_url">
        <Input placeholder="请输入邮箱" />
      </Form.Item>
      <Form.Item>
        <Button type="primary">更新</Button>
      </Form.Item>
    </Form>
  </div>
  );
};

export default Profile;
