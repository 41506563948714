import { createContext } from "react";


// ROLES = %i[owner admin moderator author banned]

export interface TeamContextProps {
    team_roles?: string[];
    team_owner?: boolean;
}

export const TeamContext = createContext<TeamContextProps>({})
