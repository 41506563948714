import { createContext } from "react";
import dayjs from '@/utils/dayjs'
import { EventItem } from "./types";
import { Level, User } from "@/types";


export interface FetchParams {
    start?: dayjs.Dayjs;
    end?: dayjs.Dayjs;
    user_id?: string;
}

export interface SchedulerContextProps{
    view?: string;
    currentDate?: dayjs.Dayjs;
    start?: string;
    loading?: boolean;
    hours?: number[];
    visible?: boolean;
    currentItem?:null | EventItem;
    draggable?: boolean;
    multiple?: boolean;
    events?: EventItem[];
    users?: User[];
    levels?: Level[];
    handleClose?: () => void;
    onDrop?: (eventId: string,start: dayjs.Dayjs) => void;
    handleSetState?:(obj: any) =>void;
    handleGotoDay?: (date: dayjs.Dayjs) => void;
    fetchEvents?:(params: FetchParams) => void;
    createEvents?:(event: EventItem) => void;
    currentUser?: string;
    removeEvents?:(event: EventItem) => void;
    cellId?: string;
}

export const defaultProps  = {
}

export const SchedulerContext = createContext<SchedulerContextProps>(defaultProps);
