import { useCallback, useState } from 'react';
import { Menu } from 'antd';
import Profile from './profile';

type SettingsStateKeys = 'base' | 'system' | 'notification';
type SettingState = {
  mode: 'inline' | 'horizaontal';
  selectedKey: SettingsStateKeys;
};

const Settings = () => {
  const [initConfig, setInitConfig] = useState<SettingState>({
    mode: 'inline',
    selectedKey: 'base',
  });
  const menuItems = [
    {
      label: '基本信息',
      key: 'base',
    },
    {
      label: '系统设置',
      key: 'system',
    },
    {
      label: '消息通知',
      key: 'notification',
    },
  ];

  const renderChidlren = useCallback(() => {
    const { selectedKey } = initConfig;
    switch (selectedKey) {
      case 'base':
        return <Profile />;
      case 'notification':
        return <Profile />;
      case 'system':
        return <Profile />;
    }
  }, [initConfig]);
  return (
    <div className="flex bg-white min-h-screen">
      <div className=" w-64">
        <Menu
          items={menuItems}
          mode={initConfig.mode}
          selectedKeys={[initConfig.selectedKey]}
          onClick={(e) => {
            setInitConfig({
              ...initConfig,
              selectedKey: e.key as SettingsStateKeys,
            });
          }}
        />
      </div>
      <div className="flex flex-1 p-10">{renderChidlren()}</div>
    </div>
  );
};

export default Settings;
