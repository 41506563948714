import { useContext, useMemo, useState } from 'react';
import {
  Button,
  Drawer,
  Popconfirm,
  Popover,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import {
  CalendarOutlined,
  CloseOutlined,
  DeleteOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  EditOutlined,
} from '@ant-design/icons';
import MarkdownPreview from '@/components/markdown-editor/preview';
import useDrag from '../hooks/useDrag';
import dayjs from '@/utils/dayjs';
import Cell from '../Cell';
import { SchedulerContext } from '../context';

const Item = ({
  event,
  hasPrev = false,
  header = false,
  hasNext = false,
  hideDate = false,
}) => {
  const { draggable, handleSetState, removeEvents } =
    useContext(SchedulerContext);
  const dragProps = useDrag(event);
  const [clicked, setClicked] = useState(false);

  const handleClickChange = (open: boolean) => {
    setClicked(open);
  };

  const Title = ({ children }) => (
    <div className="flex items-center justify-between">
      <Button
        type="text"
        icon={<CloseOutlined />}
        onClick={(e) => setClicked(false)}
      />
      <div>{children}</div>
      <div>
        <Space>
          <Tooltip title="点击编辑任务">
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={(e) => {
                setClicked(false);
                handleSetState?.({ visible: true, currentItem: event });
              }}
            />
          </Tooltip>
          <Tooltip title="点击删除任务">
            <Popconfirm
              title="确定要删除任务吗？"
              onConfirm={async (e) => {
                setClicked(false);
                await removeEvents?.(event);
              }}
            >
              <Button type="text" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Tooltip>
        </Space>
      </div>
    </div>
  );

  const content = (
    <div className="flex flex-1 flex-col">
      <div>
        <CalendarOutlined className="text-indigo-400 font-bold" />{' '}
        <span>
          {dayjs(event.start).format('YYYY-MM-DD HH:mm A')} -{' '}
          {dayjs(event.end).format('YYYY-MM-DD HH:mm A')}
        </span>
      </div>
    </div>
  );

  const isDraggable = useMemo(() => {
    if (event.disabled) return false;

    let can = typeof draggable !== 'undefined' ? draggable : true;

    return can;
  }, [draggable, event.disabled]);

  const renderItem = useMemo(() => {
    if (header) {
      return (
        <div
          className={`flex justify-between items-center p-1 rounded-sm py-1`}
          style={{
            color: 'white',
          }}
        >
          {hasPrev ? (
            <div
              style={{ width: 120, textAlign: 'left', paddingLeft: '0.25rem' }}
            >
              <DoubleLeftOutlined className="" />
            </div>
          ) : (
            <div
              style={{ width: 120, textAlign: 'left', paddingLeft: '0.25rem' }}
            >
              {dayjs(event.start).format('HH:mm A')}
            </div>
          )}
          <div className="ellipsis">{event.title}</div>
          {hasNext ? (
            <div style={{ width: 120, textAlign: 'right' }}>
              <DoubleRightOutlined />
            </div>
          ) : (
            <div style={{ width: 120, textAlign: 'right' }}>
              {dayjs(event.end).format('HH:mm A')}
            </div>
          )}
        </div>
      );
    }

    return (
      <div
        style={{
          padding: !hideDate ? '2px 6px' : '0px 6px',
          top: 0,
          left: 4,
          position: 'absolute',
          width: '99%',
          height: '100%',
        }}
        {...dragProps}
        draggable={isDraggable}
      >
        <Typography.Paragraph
          className="text-white text-left h-[20px]"
          style={{ marginBottom: 0, color: 'white' }}
          ellipsis
        >
          {event.title}
        </Typography.Paragraph>
        {!hideDate && (
          <Typography.Paragraph
            className="text-white  text-left  p-0 "
            style={{ marginBottom: 0, color: 'white' }}
            ellipsis
          >
            {dayjs(event.start).format('HH:mm')} -{' '}
            {dayjs(event.end).format('HH:mm')}
          </Typography.Paragraph>
        )}
      </div>
    );
  }, [hasNext, hasPrev, event]);

  const renderView = () => {
    if (event.disabled) {
      return (
        <Button
          style={{
            width: '100%',
            height: '100%',
            padding: '0',
            borderRadius: 5,
            backgroundColor: '#bfbfbf',
            position: 'relative',
            boxShadow: 'none',
            border: 0,
          }}
          disabled
          className="shadow-2xl before:absolute before:left-0 before:h-full before:top-0 before:content-normal before:bg-red-500 before:w-[5px] before:rounded-t-full before:rounded-l-full"
        >
          {renderItem}
        </Button>
      );
    }
    // return (
    //   <Popover
    //     trigger={['click']}
    //     placement="topLeft"
    //     title={<Title>{event.title}</Title>}
    //     content={content}
    //     open={clicked}
    //     onOpenChange={handleClickChange}
    //   >
    //     <Button
    //       style={{
    //         width: '100%',
    //         height: '100%',
    //         padding: '0',
    //         color: 'white',
    //         backgroundColor: event.color ? event.color : '#7d72f2',
    //         borderRadius: 5,
    //         position: 'relative',
    //         boxShadow: 'none',
    //         border: 0
    //       }}
    //       className="shadow-2xl  before:absolute before:left-0 before:h-full before:top-0 before:content-normal before:bg-indigo-600 before:w-[5px] rounded-t-full before:rounded-l-full"
    //     >
    //       {renderItem}
    //     </Button>
    //   </Popover>
    // );

    return (
      <>
        <Button
          style={{
            width: '100%',
            height: '100%',
            padding: '0',
            color: 'white',
            backgroundColor: event.color ? event.color : '#7d72f2',
            borderRadius: 5,
            position: 'relative',
            boxShadow: 'none',
            border: 0,
          }}
          onClick={(e) => handleClickChange(true)}
          className="shadow-2xl  before:absolute before:left-0 before:h-full before:top-0 before:content-normal before:bg-indigo-600 before:w-[5px] rounded-t-[5px] before:rounded-l-[5px]"
        >
          {renderItem}
        </Button>
        <Drawer
          title={
            <Title>
              <div className='flex items-center justify-center py-2'>
                <Tag color={event.color}>{event.label}</Tag>
                <div className=" text-xl font-bold">{event.title}</div>
              </div>
              <span className=" text-sm font-bold">
                {dayjs(event.start).format('YYYY-MM-DD HH:mm A')} 至{' '}
                {dayjs(event.end).format('YYYY-MM-DD HH:mm A')}
              </span>
            </Title>
          }
          open={clicked}
          onClose={(e) => handleClickChange(false)}
          width={1000}
          destroyOnClose
          closable={false}
          maskClosable
        >
          {event.body && <MarkdownPreview value={event.body} />}
        </Drawer>
      </>
    );
  };

  return renderView();
};

export default Item;
