import { useContext } from "react"
import { SchedulerContext } from "./context"
import TabHeaders from "./TabHeaders"
import { Spin } from "antd"


export const WithResources = ({children}) =>{
    const {multiple,users,loading} = useContext(SchedulerContext)
    if(multiple && users?.length){
        return <TabHeaders >{children}</TabHeaders>
    }

    return children;
}
